import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router";
import Profile from "../resources/Profile";
import ResourceList from "../components/resources/ResourceList";
import ResourceView from "../components/resources/ResourceView";
import User from "../resources/User";
import { Box, CircularProgress } from "@material-ui/core";
import { ReduxState } from "../interfaces/Shared";
import { useSelector } from "react-redux";
const Accounts = lazy(() => import("../components/admin/accounts/Accounts"));
const AccountView = lazy(
  () => import("../components/admin/accounts/AccountView")
);
const AdminLicenseRequestDetails = lazy(
  () => import("../components/admin/licenses/AdminLicenseRequestDetails")
);
const AuthRoute = lazy(() => import("./AuthRoute"));
const BillingInformation = lazy(
  () => import("../components/settings/BillingInformation")
);
const Cart = lazy(() => import("../components/checkout/Cart"));
const ChangePassword = lazy(
  () => import("../components/settings/ChangePassword")
);
const EditLicenseRequest = lazy(
  () => import("../components/licenses/EditLicenseRequest")
);
const EditProfile = lazy(() => import("../components/settings/EditProfile"));
const Notifications = lazy(
  () => import("../components/settings/Notifications")
);
const UserList = lazy(() => import("../components/settings/UserList"));
const LicenseDetails = lazy(
  () => import("../components/licenses/LicenseDetails")
);
const Licenses = lazy(() => import("../components/licenses/Licenses"));
const OrganizationInformation = lazy(
  () => import("../components/settings/OrganizationInformation")
);
const ProductView = lazy(() => import("../components/search/ProductView"));
const ProductLicenses = lazy(
  () => import("../components/search/ProductLicenses")
);
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const PublicRoute = lazy(() => import("./PublicRoute"));
const Queue = lazy(() => import("../components/queue/Queue"));
const RequestLicenses = lazy(
  () => import("../components/licenses/RequestLicenses")
);
const Review = lazy(() => import("../components/checkout/Review"));
const Search = lazy(() => import("../components/search/Search"));
const SignContracts = lazy(
  () => import("../components/checkout/SignContracts")
);
const UserView = lazy(() => import("../components/settings/UserView"));
const UserInvite = lazy(() => import("../components/settings/UserInvite"));
const Terms = lazy(() => import("../components/Terms"));
const LicenseTypeView = lazy(
  () => import("../components/admin/licenses/LicenseTypeView")
);
const Faq = lazy(() => import("../components/Faq"));

const user = new User();
const NoMatch = () => <h1>404</h1>;
const Users = () => <ResourceList resource={user} />;
const UsersViewResource = () => <ResourceView resource={user} />;
const profile = new Profile();
const ProfileComponent = () => <ResourceView resource={profile} />;

const Routes = () => {
  const user = useSelector((state: ReduxState) => state.app.user);
  const loggedIn = user !== null && user.username !== null;
  return (
    <Suspense
      fallback={
        <Box display="flex" flexDirection="row" justifyContent="center" pt={20}>
          <CircularProgress />
        </Box>
      }
    >
      <Switch>
        <ProtectedRoute
          exact
          user={user}
          path="/"
          render={Search}
          loggedIn={loggedIn}
        />
        <Route
          exact
          path="/auth"
          component={() => <Redirect to="/auth/start" />}
        />
        <AuthRoute path="/auth/start" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signin" loggedIn={loggedIn} override="SignIn" />
        <AuthRoute path="/auth/forgot-password" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/selectlicense" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/define" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/units" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/onetime" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/selectuses" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/recommendation" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/verifylocation" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/contact" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/org" loggedIn={loggedIn} />
        <AuthRoute path="/auth/signup/terms" loggedIn={loggedIn} />
        <AuthRoute
          path="/auth/signup/signup"
          withAuthenticator={true}
          override="SignUp"
          loggedIn={loggedIn}
        />
        <PublicRoute exact path="/faq" render={Faq} />
        <PublicRoute exact path="/search/product/:id" render={ProductView} />
        <PublicRoute
          exact
          path="/license-details/:id"
          render={LicenseDetails}
          user={user}
          loggedIn={loggedIn}
        />
        <PublicRoute
          exact
          path="/license-details/:id/edit"
          render={EditLicenseRequest}
          user={user}
          loggedIn={loggedIn}
        />
        <PublicRoute
          exact
          path="/search/product/:id/licenses"
          render={ProductLicenses}
        />
        <PublicRoute exact path="/terms" render={Terms} />
        <ProtectedRoute
          exact
          path="/accounts"
          render={Accounts}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/accounts/:sub"
          render={AccountView}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/checkout/cart"
          render={Cart}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/checkout/sign"
          render={SignContracts}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/checkout/review"
          render={Review}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/licenses"
          render={Licenses}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/licensetypes"
          render={Licenses}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/licenses/request"
          render={RequestLicenses}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/licenses/details/:id"
          render={AdminLicenseRequestDetails}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/license-types/:id"
          render={LicenseTypeView}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/licenses/details/:id"
          render={AdminLicenseRequestDetails}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          path="/profile"
          render={ProfileComponent}
          user={user}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/queue"
          render={Queue}
          loggedIn={loggedIn}
        />
        <PublicRoute exact path="/search" render={Search} loggedIn={loggedIn} />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/billing-information"
          render={BillingInformation}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/change-password"
          render={ChangePassword}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/edit-profile"
          render={EditProfile}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/manage-notifications"
          render={Notifications}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/manage-users"
          render={UserList}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/view-user/:sub"
          render={UserView}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/invite-user"
          render={UserInvite}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/settings/organization-information"
          render={OrganizationInformation}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/users"
          render={Users}
          loggedIn={loggedIn}
        />
        <ProtectedRoute
          exact
          user={user}
          path="/users/:id"
          render={UsersViewResource}
          loggedIn={loggedIn}
        />
        <Route component={NoMatch} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
