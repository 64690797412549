import React, { useState, useEffect } from "react";
import { AccountTab } from "../interfaces/Accounts";

const userTypeList = ["All", "Commercial", "NonCommercial", ""];
//creates type from array of values
type userTypes = typeof userTypeList[number];

type accountsPerPageType = "All" | number;

interface AccountsSearchContextType {
  searchText: string;
  userType: userTypes;
  accountsPerPage: accountsPerPageType;
  selectedTab: AccountTab;
  updateSearchText: (newValue: string) => void;
  updateUserType: (newValue: userTypes) => void;
  updateAccountsPerPage: (newValue: accountsPerPageType) => void;
  updateSelectedTab: (newValue: AccountTab) => void;
}

export const AccountsSearchContext = React.createContext<
  AccountsSearchContextType | undefined
>(undefined);

export const AccountsSearchProvider: React.FC<React.ReactNode> = ({
  children
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [userType, setUserType] = useState<userTypes>("");
  const [accountsPerPage, setAccountsPerPage] = useState<accountsPerPageType>(
    10
  );
  const [selectedTab, setSelectedTab] = useState<AccountTab>("All Users");

  // Update methods
  const updateSearchText = (newValue: string) => setSearchText(newValue);

  const updateUserType = (newValue: userTypes) => setUserType(newValue);

  const updateAccountsPerPage = (newValue: accountsPerPageType) =>
    setAccountsPerPage(newValue);

  const updateSelectedTab = (newValue: AccountTab) => setSelectedTab(newValue);

  const contextValue: AccountsSearchContextType = {
    searchText,
    userType,
    accountsPerPage,
    selectedTab,
    updateSearchText,
    updateUserType,
    updateAccountsPerPage,
    updateSelectedTab
  };

  return (
    <AccountsSearchContext.Provider value={contextValue}>
      {children}
    </AccountsSearchContext.Provider>
  );
};

export const useAccountsSearchContext = () => {
  const context = React.useContext(AccountsSearchContext);
  if (context === undefined) {
    throw new Error(
      "useAccountsSearchContext must be used within a AccountsSearchProvider"
    );
  }
  return context;
};
