import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import { getProductLicenses } from "./graphql/queries";
import Amplify from "aws-amplify";
import { default as config } from "../config";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

class ProductService extends BaseService {
  async getProductLicenses(getProductLicensesInput: {
    productId: string;
    searchText: string;
    page: string;
    rowsPerPage: string;
  }) {
    const response = (await API.graphql(
      graphqlOperation(getProductLicenses, { getProductLicensesInput })
    )) as any;
    console.log(
      "response.data.getProductLicenses",
      response.data.getProductLicenses
    );
    return response.data.getProductLicenses;
  }
}

export const productService = new ProductService();
export default productService;
