import { PendingAccountsSummary } from "integrity-owls-logic/dist/interfaces";
import { SET_PENDING_ACCOUNTS_SUMMARY } from "../constants";

export interface SetPendingAccountSummaryReturn {
  type: string;
  payload: PendingAccountsSummary;
}

export const setPendingAccountsSummary: (
  pendingAccountsSummary: PendingAccountsSummary
) => SetPendingAccountSummaryReturn = pendingAccountsSummary => {
  return {
    type: SET_PENDING_ACCOUNTS_SUMMARY,
    payload: pendingAccountsSummary
  };
};
