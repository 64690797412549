import {
  SET_SEARCH_ALBUM_TITLE,
  SET_SEARCH_ARTIST,
  SET_SEARCH_TEXT,
  SET_SEARCH_PAGE,
  SET_SEARCH_PUBLISHER,
  SET_SEARCH_ROWS_PER_PAGE,
  SET_SEARCH_SHOW_ONLY_SONGS,
  SET_SEARCH_SHOW_ONLY_MASTERS,
  SET_SEARCH_SONG_TITLE,
  SET_SEARCH_SORT_BY,
  SET_SEARCH_SORT_DIRECTION,
  SET_SEARCH_SELECTED_WORK,
  SET_SEARCH_SONG_WRITER
} from "../constants";

export const setSearchAlbumTitle = (albumTitle: string) => {
  return {
    type: SET_SEARCH_ALBUM_TITLE,
    payload: albumTitle
  };
};

export const setSearchArtist = (searchArtist: string) => {
  return {
    type: SET_SEARCH_ARTIST,
    payload: searchArtist
  };
};

export const setSearchText = (searchText: string) => {
  return {
    type: SET_SEARCH_TEXT,
    payload: searchText
  };
};

export const setSearchPublisher = (searchPublisher: string) => {
  return {
    type: SET_SEARCH_PUBLISHER,
    payload: searchPublisher
  };
};

export const setSearchSongTitle = (searchSongTitle: string) => {
  return {
    type: SET_SEARCH_SONG_TITLE,
    payload: searchSongTitle
  };
};

export const setSearchSongWriter = (searchSongWriter: string) => {
  return {
    type: SET_SEARCH_SONG_WRITER,
    payload: searchSongWriter
  };
};

export const setSearchPage = (page: number) => {
  return {
    type: SET_SEARCH_PAGE,
    payload: page
  };
};

export const setSearchRowsPerPage = (rowsPerPage: number) => {
  return {
    type: SET_SEARCH_ROWS_PER_PAGE,
    payload: rowsPerPage
  };
};

export const setSearchOnlySongs = (showOnlySongs: boolean) => {
  return {
    type: SET_SEARCH_SHOW_ONLY_SONGS,
    payload: showOnlySongs
  };
};

export const setSearchOnlyMasters = (showOnlyMasters: boolean) => {
  return {
    type: SET_SEARCH_SHOW_ONLY_MASTERS,
    payload: showOnlyMasters
  };
};

export const setSearchSortBy = (sortBy: string) => {
  return {
    type: SET_SEARCH_SORT_BY,
    payload: sortBy
  };
};

export const setSearchSortDirection = (sortDirection: string) => {
  return {
    type: SET_SEARCH_SORT_DIRECTION,
    payload: sortDirection
  };
};

export const setSearchSelectedWork = (selectedWork: string) => {
  return {
    type: SET_SEARCH_SELECTED_WORK,
    payload: selectedWork
  };
};
