import { User } from "integrity-owls-logic/dist";
import { SET_USER } from "../../constants";
import { ReduxAppState } from "../../interfaces/Shared";

const INITIAL_STATE: ReduxAppState = {
  user: null
};

interface AppAction {
  type: string;
  payload: User;
}

export default (
  state: ReduxAppState = INITIAL_STATE,
  action: AppAction
): ReduxAppState => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
