import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Box, CircularProgress } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import { BLACK, GRAY_DARKEST } from "./constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Routes from "./routes";
import { profileService } from "./services/ProfileService";
import PropTypes from "prop-types";
import {
  setPendingAccountsSummary,
  setProfile,
  setProjects,
  setQueueSummary,
  setUser
} from "./actions";
import {
  accountService,
  licenseRequestService,
  projectService
} from "../src/services";
import "./App.scss";
import { CartProvider } from "./context/cart";
import { WorkListProvider } from "./context/licenseRequestList";
import { AccountsSearchProvider } from "./context/accountsSearch";
import { ProjectListProvider } from "./context/projectList";

const Providers = ({ children }) => {
  return (
    <CartProvider>
      <WorkListProvider>
        <ProjectListProvider>
          <AccountsSearchProvider>{children}</AccountsSearchProvider>
        </ProjectListProvider>
      </WorkListProvider>
    </CartProvider>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const profile = await profileService.fetchMyProfile();
      const projects = await projectService.getProjects();
      if (user) {
        // only get queue summary if user is logged in
        const queueSummary = await licenseRequestService.getQueueSummary();
        console.log("Queue", queueSummary);
        this.props.setQueueSummary(queueSummary);
      }
      if (profile) {
        // get pending accounts for admin users only
        if (
          profile.organization &&
          profile.organization.organizationType &&
          profile.organization.organizationType === "INTERNAL"
        ) {
          const pendingAccountsSummary = await accountService.getPendingAccountsSummary();
          if (pendingAccountsSummary) {
            this.props.setPendingAccountsSummary(pendingAccountsSummary);
          }
        }
      }
      this.props.setProjects(projects);
      this.props.setUser(user);
      this.props.setProfile(profile);
    } catch (e) {
      console.log("error getting current user", e);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Box display="flex" flexDirection="row" justifyContent="center" pt={20}>
          <CircularProgress />
        </Box>
      );
    }
    return (
      <ConnectedRouter history={this.props.history}>
        <Providers>
          <Routes />
        </Providers>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile
});

const mapDispatchToProps = dispatch => {
  return {
    setPendingAccountsSummary: account => {
      dispatch(setPendingAccountsSummary(account));
    },
    setProfile: profile => {
      dispatch(setProfile(profile));
    },
    setProjects: projects => {
      dispatch(setProjects(projects));
    },
    setQueueSummary: summary => dispatch(setQueueSummary(summary)),
    setUser: user => {
      dispatch(setUser(user));
    }
  };
};

const styles = makeStyles({
  bgBlack: {
    background: BLACK
  },
  bgGrayDarkest: {
    background: GRAY_DARKEST
  }
});

App.propTypes = {
  setPendingAccountsSummary: PropTypes.func.isRequired,
  setProfile: PropTypes.func.isRequired,
  setProjects: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setQueueSummary: PropTypes.func.isRequired
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default withStyles(styles)(connectedApp);
