// *** Colors ***
export const BLUE = "#1aa3ff";
export const GREEN = "#45e685";
export const YELLOW = "#ecc30b";
export const RED = "#eb4a6f";

export const WHITE = "#f6fafd";
export const GRAY_LIGHTER = "#ebeef3";
export const GRAY_LIGHT = "#d7e0e7";
export const GRAY = "#7f92a3";
export const GRAY_DARK = "#536476";
export const GRAY_DARKER = "#464953";
export const GRAY_DARKEST = "#363940";
export const BLACK = "#2f3137";

// *** informational constants ***
export const US_INTEGRITY_EMAIL = "LicensingUSA@integritymusic.com";
export const UK_INTEGRITY_EMAIL = "LicensingUK@integritymusic.com";

// *** Account actions ***
export const SET_PENDING_ACCOUNTS_SUMMARY = "SET_PENDING_ACCOUNTS_SUMMARY";

// *** App actions ***
export const SET_USER = "SET_USER";
export const SET_AUTH_STATE = "SET_AUTH_STATE";

// *** Search actions ***
export const SET_SEARCH_ALBUM_TITLE = "SET_SEARCH_ALBUM_TITLE";
export const SET_SEARCH_ARTIST = "SET_SEARCH_ARTIST";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const SET_SEARCH_PUBLISHER = "SET_SEARCH_PUBLISHER";
export const SET_SEARCH_ROWS_PER_PAGE = "SET_SEARCH_ROWS_PER_PAGE";
export const SET_SEARCH_SHOW_ONLY_SONGS = "SET_SEARCH_SHOW_ONLY_SONGS";
export const SET_SEARCH_SHOW_ONLY_MASTERS = "SET_SEARCH_SHOW_ONLY_MASTERS";
export const SET_SEARCH_SONG_TITLE = "SET_SEARCH_SONG_TITLE";
export const SET_SEARCH_SONG_WRITER = "SET_SEARCH_SONG_WRITER";
export const SET_SEARCH_SORT_BY = "SET_SEARCH_SORT_BY";
export const SET_SEARCH_SORT_DIRECTION = "SET_SEARCH_SORT_DIRECTION";

// *** License Request actions ***
export const SET_QUEUE_SUMMARY = "SET_QUEUE_SUMMARY";
export const SET_LICENSES_PAGE = "SET_LICENSES_PAGE";
export const SET_LICENSES_ROWS_PER_PAGE = "SET_LICENSES_ROWS_PER_PAGE";
export const SET_LICENSES_SEARCH_TEXT = "SET_LICENSES_SEARCH_TEXT";
export const SET_LICENSES_SELECTED_ORGANIZATION =
  "SET_LICENSES_SELECTED_ORGANIZATION";
export const SET_LICENSES_SELECTED_PROJECT = "SET_LICENSES_SELECTED_PROJECT";
export const SET_LICENSES_SELECTED_TAB = "SET_LICENSES_SELECTED_TAB";
export const SET_LICENSES_SELECTED_USER_ID = "SET_LICENSES_SELECTED_USER_ID";
export const SET_LICENSES_SELECTED_LICENSE_TYPE_ID =
  "SET_LICENSES_SELECTED_LICENSE_TYPE_ID";
export const SET_LICENSES_TOTAL_RECORDS = "SET_LICENSES_TOTAL_RECORDS";
export const SET_LICENSES_SORT_BY = "SET_LICENSES_SORT_BY";
export const SET_LICENSES_SORT_DIRECTION = "SET_LICENSES_SORT_DIRECTION";
export const SET_LICENSES_BEGIN_FILTER_DATE = "SET_LICENSES_BEGIN_FILTER_DATE";
export const SET_LICENSES_END_FILTER_DATE = "SET_LICENSES_END_FILTER_DATE";

// *** Product actions
export const SET_PRODUCT_LICENSES = "SET_PRODUCT_LICENSES";
export const SET_PRODUCT_LICENSES_LOADING = "SET_PRODUCT_LICENSES_LOADING";
export const SET_PRODUCT_LICENSES_TOTAL = "SET_PRODUCT_LICENSES_TOTAL";
export const SET_PRODUCT_LICENSES_ERROR = "SET_PRODUCT_LICENSES_ERROR";
export const SET_PRODUCT_LICENSES_PAGE = "SET_PRODUCT_LICENSES_PAGE";
export const SET_PRODUCT_LICENSES_ROWS_PER_PAGE =
  "SET_PRODUCT_LICENSES_ROWS_PER_PAGE";
export const SET_PRODUCT_LICENSES_SEARCH_TEXT =
  "SET_PRODUCT_LICENSES_SEARCH_TEXT";

// *** Project actions
export const SET_PROJECTS = "SET_PROJECTS";

// *** Work actions ***
export const SET_SEARCH_SELECTED_WORK = "SET_SEARCH_SELECTED_WORK";
export const SET_SELECTED_WORKS = "SET_SELECTED_WORKS";

// *** Profile actions ***
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const dateFormatText = "MMM Do, YYYY";
