import {
  SET_LICENSES_BEGIN_FILTER_DATE,
  SET_LICENSES_END_FILTER_DATE,
  SET_LICENSES_PAGE,
  SET_LICENSES_ROWS_PER_PAGE,
  SET_LICENSES_SEARCH_TEXT,
  SET_LICENSES_SELECTED_LICENSE_TYPE_ID,
  SET_LICENSES_SELECTED_ORGANIZATION,
  SET_LICENSES_SELECTED_PROJECT,
  SET_LICENSES_SELECTED_TAB,
  SET_LICENSES_SELECTED_USER_ID,
  SET_LICENSES_SORT_BY,
  SET_LICENSES_SORT_DIRECTION,
  SET_LICENSES_TOTAL_RECORDS,
  SET_QUEUE_SUMMARY
} from "../constants";

export const setQueueSummary = (queue: any) => {
  return {
    type: SET_QUEUE_SUMMARY,
    payload: queue
  };
};

export const setLicensesPage = (page: any) => {
  return {
    type: SET_LICENSES_PAGE,
    payload: page
  };
};

export const setLicensesRowsPerPage = (rowsPerPage: any) => {
  return {
    type: SET_LICENSES_ROWS_PER_PAGE,
    payload: rowsPerPage
  };
};

export const setLicensesSearchText = (searchText: any) => {
  return {
    type: SET_LICENSES_SEARCH_TEXT,
    payload: searchText
  };
};

export const setLicensesSelectedOrganization = (selectedOrganization: any) => {
  return {
    type: SET_LICENSES_SELECTED_ORGANIZATION,
    payload: selectedOrganization
  };
};

export const setLicensesSelectedProject = (selectedProject: any) => {
  return {
    type: SET_LICENSES_SELECTED_PROJECT,
    payload: selectedProject
  };
};

export const setLicensesSelectedTab = (selectedTab: any) => {
  return {
    type: SET_LICENSES_SELECTED_TAB,
    payload: selectedTab
  };
};

export const setLicensesSelectedUserId = (selectedUserId: any) => {
  return {
    type: SET_LICENSES_SELECTED_USER_ID,
    payload: selectedUserId
  };
};

export const setLicensesSelectedLicenseTypeId = (
  selectedLicenseTypeId: string
) => {
  return {
    type: SET_LICENSES_SELECTED_LICENSE_TYPE_ID,
    payload: selectedLicenseTypeId
  };
};

export const setLicensesTotalRecords = (totalRecords: any) => {
  return {
    type: SET_LICENSES_TOTAL_RECORDS,
    payload: totalRecords
  };
};

export const setLicensesSortBy = (field: string) => {
  return {
    type: SET_LICENSES_SORT_BY,
    payload: field
  };
};

export const setLicensesSortDirection = (direction: "asc" | "desc") => {
  return {
    type: SET_LICENSES_SORT_DIRECTION,
    payload: direction
  };
};

export const setLicensesBeginFilterDate = (date: Date) => {
  return {
    type: SET_LICENSES_BEGIN_FILTER_DATE,
    payload: date
  };
};

export const setLicensesEndFilterDate = (date: Date) => {
  return {
    type: SET_LICENSES_END_FILTER_DATE,
    payload: date
  };
};
