import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import { createProject, toggleProjectActiveStatus } from "./graphql/mutations";
import {
  getAllProjects,
  getCustomerProjects,
  getProjects
} from "./graphql/queries";
import Amplify from "aws-amplify";
import { default as config } from "../config";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

class ProjectService extends BaseService {
  async createProject(createProjectInput: { name: string }) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(createProject, {
          createProjectInput
        })
      )) as any;
      console.log("createprojectinput", createProjectInput);
      console.log("project created", data);
      return data.createProject;
    } catch (err) {
      console.error("Error creating project", err);
    }
  }

  async getCustomerProjects(getCustomerProjectsInput: { customerSub: string }) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getCustomerProjects, {
          getCustomerProjectsInput
        })
      )) as any;
      console.log("got customer projects", data);
      return data.getCustomerProjects;
    } catch (err) {
      console.error("Error getting projects", err);
    }
  }

  async getProjects() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getProjects, {})
      )) as any;
      // console.log('got projects', data);
      return data.getProjects;
    } catch (err) {
      console.error("Error getting projects", err);
    }
  }

  async getAllProjects(getAllProjectsInput: { organizationFilter: string }) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getAllProjects, { getAllProjectsInput })
      )) as any;
      console.log("got all projects", data.getAllProjects);
      return data.getAllProjects;
    } catch (err) {
      console.error("Error getting all projects", err);
    }
  }

  async toggleProjectActiveStatus(toggleProjectActiveStatusInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(toggleProjectActiveStatus, {
          toggleProjectActiveStatusInput
        })
      )) as any;
      console.log("toggle project data", data);
      return data.toggleProjectActiveStatus;
    } catch (err) {
      console.error("Error toggling project status", err);
    }
  }
}

export const projectService = new ProjectService();
export default projectService;
