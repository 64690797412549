import User from "./User";
import { API, graphqlOperation } from "aws-amplify";
import { IAttributeType, ActionType } from "./interfaces/IAttribute";

export class Profile extends User {
  static $name = "Profile";
  protected $getGraphQLResponseShape =
    "PK SK username firstName lastName organizationName email address1 address2 city state zip createdAt updatedAt updatedBy role GSI1PK GSI1SK";

  static makeGetGraphQLQuery(
    responseShape: string | undefined = undefined
  ): string {
    return `query GetProfile {
      getProfile {
        ${responseShape || "PK"}
        ${this.$versioned ? "version" : ""}
      }
    }`;
  }

  static async get(
    responseShape: string | undefined = undefined
  ): Promise<any> {
    const query = this.makeGetGraphQLQuery(responseShape);
    const apiresponse: any = await API.graphql(graphqlOperation(query));
    const resultKey = Object.keys(apiresponse.data).shift() as string;
    const response: any = {
      data: { [resultKey]: apiresponse.data[resultKey] }
    };
    console.log(
      "GRAPHQL GET RESPONSE",
      resultKey,
      response,
      response.data[resultKey]
    );

    return response.data[resultKey];
  }

  protected async onInitialize() {
    const ctor = this.getConstructor();
    this.$recordToLoadOnInit = {
      rawRecord: await ctor.get(this.makeGetResponseShapeFromAttributes())
    };

    if (this.getConstructor().$versioned) {
      this.$currentVersion = this.$recordToLoadOnInit.rawRecord.version;
    }

    this.$partialRecord = false;

    return Promise.resolve();
  }

  protected $defaultAttributes = [
    {
      name: "username",
      displayName: "Username",
      type: IAttributeType.Text,
      required: true,
      readOnly: [ActionType.UPDATE],
      helpText: "This cannot be changed once a user is created."
    },
    {
      name: "firstName",
      displayName: "First Name",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "lastName",
      displayName: "Last Name",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "email",
      displayName: "Email",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "address1",
      displayName: "Address1",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "address2",
      displayName: "Address2",
      type: IAttributeType.Text,
      required: false
    }
  ];
}

export default Profile;
