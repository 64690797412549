import { PendingAccountsSummary } from "integrity-owls-logic/dist/interfaces";
import { SET_PENDING_ACCOUNTS_SUMMARY } from "../../constants";
import { ReduxAccountState } from "../../interfaces/Shared";

const initialState: ReduxAccountState = {
  pendingAccountsSummary: {
    pendingAccounts: 0,
    pendingDeactivationRequests: 0,
    pendingOrgChangeRequests: 0,
    hasLicense: false
  }
};

interface Action {
  type: string;
  payload: PendingAccountsSummary;
}

const profile = (state = initialState, action: Action): ReduxAccountState => {
  switch (action.type) {
    case SET_PENDING_ACCOUNTS_SUMMARY:
      return { ...initialState, pendingAccountsSummary: action.payload };
    default:
      return state;
  }
};

export default profile;
