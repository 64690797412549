import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Amplify from "aws-amplify";
import React from "react";
import "react-app-polyfill/ie11";
import { render } from "react-dom";
import { Provider } from "react-redux";
// First party
import App from "./App";
import { default as config } from "./config";
import {
  BLACK,
  BLUE,
  GRAY,
  GRAY_DARKER,
  GRAY_DARKEST,
  WHITE
} from "./constants";
import configureStore, { history } from "./redux/store";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn:
    "https://a1557460d4814b9bac3dfa91500dc61b@o184662.ingest.sentry.io/6239641",
  environment: config.config.environment,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

const store = configureStore();

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  palette: {
    type: "dark",
    background: {
      default: BLACK
    },
    primary: {
      main: BLUE
    },
    secondary: {
      main: BLUE
    },
    grey: {
      600: GRAY,
      700: GRAY_DARKER,
      800: GRAY_DARKEST,
      900: BLACK
    }
  },
  typography: {
    fontFamily: [
      '"HelveticaNeue-Light"',
      '"Helvetica Neue Light"',
      '"Helvetica Neue"',
      '"Helvetica"',
      '"Arial"',
      '"Lucida Grande"',
      "-apple-system",
      "BlinkMacSystemFont",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    overline: {
      letterSpacing: 2,
      fontWeight: 600,
      color: "GRAY",
      fontSize: ".6rem"
    },
    body1: {
      fontSize: "18px",
      lineHeight: "30px"
    },
    body2: {
      fontSize: "14px",
      lineHeight: "24px"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        fontSize: "1rem",
        letterSpacing: "0",
        fontWeight: "600",
        color: WHITE + " !important",
        padding: "5px 15px",
        borderRadius: 7
      },
      containedSizeLarge: {
        fontSize: "1.25rem",
        padding: "10px 15px"
      },
      outlinedSizeLarge: {
        fontSize: "1.25rem",
        padding: "10px 15px"
      }
    }
  },
  spacing: 10
});

const renderApp = () => {
  render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App history={history} />
      </MuiThemeProvider>
    </Provider>,
    document.getElementById("root")
  );
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// import React from "react";
// import { render } from "react-dom";
// import { Provider } from "react-redux";

// // First party
// import AppWithAuth from "./AppWithAuth";
// import configureStore, { history } from "./redux/store";
// import * as serviceWorker from "./serviceWorker";

// const store = configureStore();

// const renderApp = () => {
//   render(
//     <Provider store={store}>
//       <AppWithAuth history={history} />
//     </Provider>,
//     document.getElementById("root")
//   );
// };

// if (process.env.NODE_ENV !== "production" && (module as any).hot) {
//   (module as any).hot.accept("./AppWithAuth", renderApp);
// }

// renderApp();

// serviceWorker.unregister();
