import { productService } from "../services";
import {
  SET_PRODUCT_LICENSES,
  SET_PRODUCT_LICENSES_ERROR,
  SET_PRODUCT_LICENSES_LOADING,
  SET_PRODUCT_LICENSES_PAGE,
  SET_PRODUCT_LICENSES_ROWS_PER_PAGE,
  SET_PRODUCT_LICENSES_SEARCH_TEXT,
  SET_PRODUCT_LICENSES_TOTAL
} from "../constants";

export const getProductLicenses = (productId: string) => {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const {
      productLicensesSearchText,
      productLicensesPage,
      productLicensesRowsPerPage
    } = state.product;
    const payload = {
      productId,
      page: productLicensesPage,
      rowsPerPage: productLicensesRowsPerPage,
      searchText: productLicensesSearchText
    };
    dispatch(setProductLicensesLoading(true));
    try {
      const productLicensesResult = await productService.getProductLicenses(
        payload
      );
      if (!productLicensesResult.data) {
        throw new Error("error getting product");
      }
      dispatch(setProductLicenses(productLicensesResult.data));
      dispatch(setProductLicensesTotal(productLicensesResult.total));
      dispatch(setProductLicensesLoading(false));
    } catch (err) {
      dispatch(setProductLicensesError(err));
      dispatch(setProductLicensesLoading(false));
    }
  };
};

export const setProductLicensesPage = (page: string) => {
  return {
    type: SET_PRODUCT_LICENSES_PAGE,
    payload: page
  };
};

export const setProductLicensesRowsPerPage = (rowsPerPage: string) => {
  return {
    type: SET_PRODUCT_LICENSES_ROWS_PER_PAGE,
    payload: rowsPerPage
  };
};

export const setProductLicensesSearchText = (searchText: string) => {
  console.log("in setProductLicensesSearchText", searchText);
  return {
    type: SET_PRODUCT_LICENSES_SEARCH_TEXT,
    payload: searchText
  };
};

export const setProductLicenses = (productLicenses: any) => {
  return {
    type: SET_PRODUCT_LICENSES,
    payload: productLicenses
  };
};

export const setProductLicensesLoading = (loading: boolean) => {
  return {
    type: SET_PRODUCT_LICENSES_LOADING,
    payload: loading
  };
};

export const setProductLicensesError = (error: any) => {
  return {
    type: SET_PRODUCT_LICENSES_ERROR,
    payload: error
  };
};

export const setProductLicensesTotal = (total: number) => {
  return {
    type: SET_PRODUCT_LICENSES_TOTAL,
    payload: total
  };
};
