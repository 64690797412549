import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router";

const ProjectListContext = createContext(undefined);
//used for only comm users
//{project: {work: licenseRequest, work: licesneRequest}, project: {{}}}
export const ProjectListProvider = ({ children }) => {
  const [workList, setWorkList] = useState([]);
  const [currWorkIndex, setCurrWorkIndex] = useState(-1);
  const [currRequestIndex, setCurrRequestIndex] = useState(-1);
  const history = useHistory();

  const updateWorkList = data => {
    let flattenedRequests = [];

    // Flatten the nested structure
    Object.values(data).forEach(project => {
      Object.values(project).forEach(work => {
        flattenedRequests.push(work);
      });
    });

    setWorkList(flattenedRequests);
  };

  // Set the current work and request indices with bounds checks
  const setIndices = (workIndex, requestIndex) => {
    if (workIndex >= 0 && workIndex < workList.length) {
      setCurrWorkIndex(workIndex);
    } else {
      console.error(`Invalid work index: ${workIndex}`);
      return;
    }

    if (
      workIndex >= 0 &&
      workIndex < workList.length &&
      requestIndex >= 0 &&
      requestIndex < workList[workIndex].length
    ) {
      setCurrRequestIndex(requestIndex);
    } else {
      console.error(`Invalid request index: ${requestIndex}`);
    }
  };

  const hasNextLicenseRequest = () => {
    if (
      currWorkIndex >= 0 &&
      currWorkIndex < workList.length &&
      currRequestIndex >= 0 &&
      currRequestIndex < workList[currWorkIndex].length - 1
    ) {
      return true;
    }

    if (currWorkIndex < workList.length - 1) {
      return true;
    }
    return false;
  };

  const hasPreviousLicenseRequest = () => {
    if (
      currWorkIndex >= 0 &&
      currWorkIndex < workList.length &&
      currRequestIndex > 0
    ) {
      return true;
    }

    if (currWorkIndex > 0) {
      return true;
    }

    return false;
  };

  const moveToNextRequest = () => {
    if (hasNextLicenseRequest()) {
      let wIndex = currWorkIndex;
      let rIndex;
      if (currRequestIndex < workList[currWorkIndex].length - 1) {
        rIndex = currRequestIndex + 1;
        setCurrRequestIndex(currRequestIndex + 1);
      } else {
        wIndex = currWorkIndex + 1;
        rIndex = 0;
        setCurrWorkIndex(wIndex);
        setCurrRequestIndex(0);
      }
      const license = workList[wIndex][rIndex];

      history.push(`/license-details/${license.id}`, { license });
    }
  };

  const moveToPreviousRequest = () => {
    if (hasPreviousLicenseRequest()) {
      let wIndex = currWorkIndex;
      let rIndex;
      if (currRequestIndex > 0) {
        rIndex = currRequestIndex - 1;
        setCurrRequestIndex(rIndex);
      } else {
        rIndex = workList[currWorkIndex - 1].length - 1;
        wIndex = currWorkIndex - 1;
        setCurrWorkIndex(wIndex);
        setCurrRequestIndex(rIndex);
      }
      const license = workList[wIndex][rIndex];
      history.push(`/license-details/${license.id}`, {
        license
      });
    }
  };

  const contextValue = {
    updateWorkList,
    setIndices,
    hasNextLicenseRequest,
    hasPreviousLicenseRequest,
    moveToNextRequest,
    moveToPreviousRequest
  };

  return (
    <ProjectListContext.Provider value={contextValue}>
      {children}
    </ProjectListContext.Provider>
  );
};

export const useProjectListContext = () => {
  const context = useContext(ProjectListContext);
  if (context === undefined) {
    throw new Error(
      "useProjectListContext must be used within a ProjectListProvider"
    );
  }
  return context;
};
