import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface Props {
  open: boolean;
  title: string | JSX.Element;
  content: string | JSX.Element;
  onDismiss: () => void;
}

export class Alert extends React.Component<Props> {
  render() {
    const { open, title, content, onDismiss } = this.props;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDismiss()} color="default" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Alert;
