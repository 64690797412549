import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import { getAllOrganizations } from "./graphql/queries";

import Amplify from "aws-amplify";
import { default as config } from "../config";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

class OrganizationService extends BaseService {
  async getAllOrganizations() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getAllOrganizations, {})
      )) as any;
      console.log("getAllOrganizations data", data);
      return data.getAllOrganizations;
    } catch (err) {
      console.error("Error getting all organizations", err);
    }
  }
}

export const organizationService = new OrganizationService();
export default organizationService;
