import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import account from "./account";
import app from "./app";
import licenseRequest from "./licenseRequest";
import product from "./product";
import profile from "./profile";
import project from "./project";
import search from "./search";

export default (history: any) =>
  combineReducers({
    account,
    router: connectRouter(history),
    app,
    licenseRequest,
    product,
    profile,
    project,
    search
  });
