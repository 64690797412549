import {
  SET_PRODUCT_LICENSES,
  SET_PRODUCT_LICENSES_ERROR,
  SET_PRODUCT_LICENSES_LOADING,
  SET_PRODUCT_LICENSES_PAGE,
  SET_PRODUCT_LICENSES_ROWS_PER_PAGE,
  SET_PRODUCT_LICENSES_SEARCH_TEXT,
  SET_PRODUCT_LICENSES_TOTAL
} from "../../constants";
import { ReduxProductState } from "../../interfaces/Shared";

const INITIAL_STATE: ReduxProductState = {
  productLicenses: [],
  productLicensesLoading: true,
  productLicensesTotal: 0,
  productLicensesSearchText: "",
  productLicensesError: null,
  productLicensesPage: 0,
  productLicensesRowsPerPage: 10
};

interface ProductAction {
  type: string;
  payload: any;
}

export default (
  state = INITIAL_STATE,
  action: ProductAction
): ReduxProductState => {
  switch (action.type) {
    case SET_PRODUCT_LICENSES:
      return { ...state, productLicenses: action.payload };
    case SET_PRODUCT_LICENSES_ERROR:
      return { ...state, productLicensesError: action.payload };
    case SET_PRODUCT_LICENSES_LOADING:
      return { ...state, productLicensesLoading: action.payload };
    case SET_PRODUCT_LICENSES_PAGE:
      return { ...state, productLicensesPage: action.payload };
    case SET_PRODUCT_LICENSES_ROWS_PER_PAGE:
      return { ...state, productLicensesRowsPerPage: action.payload };
    case SET_PRODUCT_LICENSES_SEARCH_TEXT:
      return { ...state, productLicensesSearchText: action.payload };
    case SET_PRODUCT_LICENSES_TOTAL:
      return { ...state, productLicensesTotal: action.payload };
    default:
      return state;
  }
};
