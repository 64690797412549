export const addProjectToLicenseRequests = `mutation addProjectToLicenseRequests($addProjectToLicenseRequestsInput: AddProjectToLicenseRequestsInput) {
  addProjectToLicenseRequests(addProjectToLicenseRequestsInput: $addProjectToLicenseRequestsInput) {
    id
    status
    createdAt
    updatedAt
    project {
      id
      name
    }
    work {
      id
      title
      workType
      createdAt
      updatedAt
    }
    user {
      id
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
}`;

export const approveLicenseRequest = `mutation approveLicenseRequest($approveLicenseRequestInput: ApproveLicenseRequestInput) {
  approveLicenseRequest(approveLicenseRequestInput: $approveLicenseRequestInput) {
    id
    status
    licenseType {
      id
      name
    }
  }
}`;

export const setAdminNotes = `mutation setAdminNotes($setAdminNotesInput: SetAdminNotesInput) {
  setAdminNotes(setAdminNotesInput: $setAdminNotesInput) {
    id
    status
    licenseType {
      id
      name
    }
    adminNotes
    }
}`;

export const updateOpened = `mutation updateOpened($updateOpenedInput: UpdateOpenedInput) {
  updateOpened(updateOpenedInput: $updateOpenedInput) {
    id
    status
    opened
  }
}`;

export const denyLicenseRequest = `mutation denyLicenseRequest($denyLicenseRequestInput: DenyLicenseRequestInput) {
  denyLicenseRequest(denyLicenseRequestInput: $denyLicenseRequestInput) {
    id
    status
    licenseType {
      id
      name
    }
  }
}`;

export const completeOrder = `mutation completeOrder($orderId: String) {
  completeOrder(orderId: $orderId) {
    data
    error
  }
}`;

export const createOrUpdateStripeCard = `mutation createOrUpdateStripeCard($createOrUpdateStripeCardInput: CreateOrUpdateStripeCardInput) {
  createOrUpdateStripeCard(createOrUpdateStripeCardInput: $createOrUpdateStripeCardInput) {
    success
    error
  }
}`;

export const createOrUpdateOrder = `mutation createOrUpdateOrder($createOrUpdateOrderInput: CreateOrUpdateOrderInput) {
  createOrUpdateOrder(createOrUpdateOrderInput: $createOrUpdateOrderInput) {
    error
    data
  }
}`;

export const createLicenseRequests = `mutation createLicenseRequests($createLicenseRequestsInput: CreateLicenseRequestsInput) {
  createLicenseRequests(createLicenseRequestsInput: $createLicenseRequestsInput) {
    id
    status
    createdAt
    updatedAt
    work {
      id
      title
      workType
      createdAt
      updatedAt
    }
    user {
      id
      firstName
      lastName
      createdAt
      updatedAt
    }
    licenseType {
      id
      name
    }
  }
}`;

export const createLicenseRequest = `mutation createLicenseRequest($createLicenseRequestInput: CreateLicenseRequestInput) {
  createLicenseRequest(createLicenseRequestInput: $createLicenseRequestInput) {
    id
    status
    createdAt
    updatedAt
    work {
      id
      title
      workType
      createdAt
      updatedAt
    }
    user {
      id
      firstName
      lastName
      createdAt
      updatedAt
    }
  }
}`;

export const upsertLicenseTypeAnswers = `mutation upsertLicenseTypeAnswers($upsertLicenseTypeAnswersInput: UpsertLicenseTypeAnswersInput) {
  upsertLicenseTypeAnswers(upsertLicenseTypeAnswersInput: $upsertLicenseTypeAnswersInput) {
    id
    answer
    licenseTypeQuestion {
      id
      question
      questionName
    }
  }
}`;

export const createProject = `mutation createProject($createProjectInput: CreateProjectInput) {
  createProject(createProjectInput: $createProjectInput) {
    id
    name
    user {
      id
      firstName
      lastName
    }
  }
}`;

export const createStripeSetupIntent = `mutation createStripeSetupIntent {
  createStripeSetupIntent {
    clientSecret
  }
}`;

export const deleteStripePaymentMethod = `mutation deleteStripePaymentMethod($deleteStripePaymentMethodInput: DeleteStripePaymentMethodInput) {
  deleteStripePaymentMethod(deleteStripePaymentMethodInput: $deleteStripePaymentMethodInput) {
    id
    name
    organizationType
    stripeCustomerId
    stripePaymentMethodId
  }
}`;

export const deleteLicenseRequest = `mutation deleteLicenseRequest($deleteLicenseRequestInput: DeleteLicenseRequestInput) {
  deleteLicenseRequest(deleteLicenseRequestInput: $deleteLicenseRequestInput) {
    id
    status
    createdAt
    updatedAt
    work {
      id
      title
    }
    user {
      id
      firstName
      lastName
    }
  }
}`;

export const duplicateLicenseRequests = `mutation duplicateLicenseRequests($duplicateLicenseRequestsInput: DuplicateLicenseRequestsInput) {
  duplicateLicenseRequests(duplicateLicenseRequestsInput: $duplicateLicenseRequestsInput) {
    id
    status
    createdAt
    updatedAt
    work {
      id
      title
    }
    user {
      id
      firstName
      lastName
    }
    licenseType {
      id
      name
    }
  }
}`;

export const requireLicenseRequestUpdates = `mutation requireLicenseRequestUpdates($requireLicenseRequestUpdatesInput: RequireLicenseRequestUpdatesInput) {
  requireLicenseRequestUpdates(requireLicenseRequestUpdatesInput: $requireLicenseRequestUpdatesInput) {
    id
    status
  }
}
`;

export const updateContract = `mutation UpdateContract($updateContractInput: UpdateContractInput) {
  updateContract(updateContractInput: $updateContractInput) {
    id
  }
}`;

export const updateRates = `mutation UpdateRates($updateRatesInput: UpdateRatesInput) {
  updateRates(updateRatesInput: $updateRatesInput) {
    id
  }
}`;

export const startEnqueuedLicenseRequests = `mutation StartEnqueuedLicenseRequests($startEnqueuedLicenseRequestsInput: StartEnqueuedLicenseRequestsInput) {
  startEnqueuedLicenseRequests(startEnqueuedLicenseRequestsInput: $startEnqueuedLicenseRequestsInput) {
    id
    status
  }
}`;

export const signContractForIntegrity = `mutation SignContractForIntegrity($signContractInput: SignContractInput) {
  signContractForIntegrity(signContractInput: $signContractInput) {
    id
    status
    signedByUser
  }
}`;

export const signContractForCustomer = `mutation SignContractForCustomer($signContractInput: SignContractInput) {
  signContractForCustomer(signContractInput: $signContractInput) {
    id
    status
    signedByUser
  }
}`;

export const submitEnqueuedLicenseRequests = `mutation SubmitEnqueuedLicenseRequests($submitEnqueuedLicenseRequestsInput: SubmitEnqueuedLicenseRequestsInput) {
  submitEnqueuedLicenseRequests(submitEnqueuedLicenseRequestsInput: $submitEnqueuedLicenseRequestsInput) {
    id
    status
  }
}`;

export const toggleProjectActiveStatus = `mutation ToggleProjectActiveStatus($toggleProjectActiveStatusInput: ToggleProjectActiveStatusInput) {
  toggleProjectActiveStatus(toggleProjectActiveStatusInput: $toggleProjectActiveStatusInput) {
    id
    name
    active
  }
}`;

export const updateMyOrganization = `mutation UpdateMyOrganization($updateMyOrganizationInput: UpdateMyOrganizationInput) {
  updateMyOrganization(updateMyOrganizationInput: $updateMyOrganizationInput) {
    id
    name
    email
    address1
    address2
    city
    state
    zip
    orgChangeRequests {
      id
      fieldname
      value
      status
    }
  }
}`;

export const updateNotifications = `mutation UpdateNotifications($updateNotificationsInput: UpdateNotificationsInput) {
  updateNotifications(updateNotificationsInput: $updateNotificationsInput) {
    notifications
  }
}`;

export const getAnswerSignedUrl = `mutation GetAnswerSignedUrl($getAnswerSignedUrlInput: GetAnswerSignedUrlInput) {
  getAnswerSignedUrl(getAnswerSignedUrlInput: $getAnswerSignedUrlInput) {
    id
    signedUrl
    licenseTypeAnswerId
  }
}`;

export const deleteAnswerAttachment = `mutation DeleteAnswerAttachment($deleteAnswerAttachmentInput: DeleteAnswerAttachmentInput)
  {
    deleteAnswerAttachment(deleteAnswerAttachmentInput: $deleteAnswerAttachmentInput) {
      id
    }
  }
`;

export const updateProfile = `mutation UpdateProfile($updateProfileInput: UpdateProfileInput) {
  updateProfile(updateProfileInput: $updateProfileInput) {
    id
    sub
    email
    firstName
    lastName
    phone
    region
    address1
    address2
    city
    state
    zip
    organization {
      id
      name
      organizationType
    }
    role {
      id
      name
    }
  }
}`;

export const updateWork = `mutation UpdateWork($work: UpdateWorkInput) {
  updateWork(work: $work) {
    id
    title
    workType
    akas
    createdAt
    updatedAt
    songTitle
    ccliNumber
    totalImPercentControl
    territoryControlled
    region
    copyrightNotice
    writer1
    writer2
    writer3
    writer4
    writer5
    writer6
    writer7
    writer8
    writer9
    publisher1
    publisher2
    publisher3
    publisher4
    publisher5
    publisher6
    publisher7
    publisher8
    publisher9
    publisher10
    publisher11
    publisher12
    publisher13
    publisher14
    publisher15
    publisher16
    publisher17
    publisher18
    publisher19
    publisher20
    publisher21
    publisher22
    publisher23
    publisher24
    publisher25
    publisher26
    publisher27
    trackName
    albumName
    primaryReleaseDate
    songVersion
    releaseArtistName
    projectName
    isrcNumber
    discNumber
    sideNumber
    trackNumber
    trackArtistName
    artist
    musicMaestroId
    openPlayId
    workLicenseTypes {
      licenseType {
        id
        name
        commercial
        location
        songMaster
        __typename
      }
    }
  }
}`;

export const getSignedUrl = `mutation GetSignedUrl($uploadType: String) {
  getSignedUrl(uploadType: $uploadType) {
    id
    signedUrl
  }
}`;

export const approveAccount = `mutation approveAccount($sub: String) {
  approveAccount(sub: $sub) {
    sub
    organization {
      status
    }
  }
}`;

export const denyAccount = `mutation denyAccount($sub: String, $reason: String) {
  denyAccount(sub: $sub, reason: $reason) {
    sub
    organization {
      status
    }
  }
}`;

export const deactivateUser = `mutation deactivateUser($sub: String, $reason: String) {
  deactivateUser(sub: $sub, reason: $reason) {
    sub
  }
}`;

export const deactivateUserFromMyOrganization = `mutation deactivateUserFromMyOrganization($sub: String, $reason: String) {
  deactivateUserFromMyOrganization(sub: $sub, reason: $reason) {
    sub
  }
}`;

export const deactivateOrganization = `mutation deactivateOrganization($id: Int, $reason: String) {
  deactivateOrganization(id: $id, reason: $reason) {
    id
  }
}`;

export const deleteOrganization = `mutation deleteOrganization($id: Int) {
  deleteOrganization(id: $id) {
    message
  }
}`;

export const requestDeactivation = `mutation requestDeactivation {
  requestDeactivation {
    id
    deactivationRequestedBy {
      firstName
      lastName
      sub
    }
    deactivationRequestedAt
  }
}`;

export const approveOrgChangeRequest = `mutation approveOrgChangeRequest($id: Int) {
  approveOrgChangeRequest(id: $id) {
    id
    name
  }
}`;

export const denyOrgChangeRequest = `mutation denyOrgChangeRequest($id: Int, $reason: String) {
  denyOrgChangeRequest(id: $id, reason: $reason) {
    id
    name
  }
}`;

export const createUserFromOrgAdmin = `mutation createUserFromOrgAdmin($createUserInput: CreateUserInput) {
  createUserFromOrgAdmin(createUserInput: $createUserInput) {
    id
    sub
    username
    email
  }
}`;

export const updateUser = `mutation updateUser($updateUserInput: UpdateUserInput) {
  updateUser(updateUserInput: $updateUserInput) {
    id
    sub
    firstName
    lastName
    email
    username
    role {
      name
    }
    permissions {
      id
      name
    }
  }
}`;

export const reactivateUser = `mutation reactivateUser($sub: String) {
  reactivateUser(sub: $sub) {
    sub
  }
}`;

export const reactivateUserFromMyOrganization = `mutation reactivateUserFromMyOrganization($sub: String) {
  reactivateUserFromMyOrganization(sub: $sub) {
    sub
  }
}`;

export const denyDeactivationRequest = `mutation denyDeactivationRequest($id: Int, $reason: String) {
  denyDeactivationRequest(id: $id, reason: $reason) {
    id
    name
    deactivationRequestedBy {
      firstName
      lastName
      email
    }
    deactivationRequestedAt
  }
}`;

export const approveDeactivationRequest = `mutation approveDeactivationRequest($id: Int) {
  approveDeactivationRequest(id: $id) {
    id
    name
    deactivationRequestedBy {
      firstName
      lastName
      email
    }
    deactivationRequestedAt
  }
}`;

export const changeOrganizationType = `mutation changeOrganizationType($sub: String, $organizationType: String) {
  changeOrganizationType(sub: $sub, organizationType: $organizationType) {
    id
    sub
    firstName
    lastName
    username
    email
    phone
    address1
    address2
    city
    state
    zip
    town
    county
    postcode
    region
    status
    organization {
      id
      name
      status
      organizationType
      address1
      address2
      city
      state
      zip
      town
      county
      postcode
      landLine
      email
      stripePaymentMethodId
      region
      licNoDistribution
      licNumberOfUnits
      licOneTimePayment
      licDefinitions
      licUseCases
    }
  }
}`;

export const reactivateOrganization = `mutation reactivateOrganization($id: Int) {
  reactivateOrganization(id: $id) {
    id
  }
}`;

export const editLicenseType = `mutation editLicenseType($id: Int, $contract: String) {
  editLicenseType(id: $id, contract: $contract) {
    id
    name
    songMaster
    location
    commercial
    contract
    licenseTypeQuestions {
      id
      question
      questionName
      autoPopulated
      answerType
      internal
    }
  }
}`;

// export const approveLicenseRequest = `mutation approveLicenseRequest($approveLicenseRequestInput: ApproveLicenseRequestInput) {

export const voidLicenseRequest = `mutation voidLicenseRequest($voidLicenseRequestInput: VoidLicenseRequestInput) {
  voidLicenseRequest(voidLicenseRequestInput: $voidLicenseRequestInput) {
    id
  }
}`;
