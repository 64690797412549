export const fetchMyProfile = `query GetProfile {
  getProfile {
    id
    sub
    email
    phone
    firstName
    lastName
    address1
    address2
    city
    state
    zip
    town
    county
    postcode
    region
    notifications
    organization {
      id
      name
      organizationType
      stripeCustomerId
      stripePaymentMethodId
      email
      address1
      address2
      city
      state
      zip
      town
      county
      postcode
      status
      region
      orgChangeRequests {
        id
        fieldname
        value
        status
      }
      deactivationRequestedBy {
        firstName
        lastName
      }
      deactivationRequestedAt
    }
    role {
      id
      name
    }
    permissions {
      slug
    }
  }
}`;

export const findOrCreateContract = `query findOrCreateContract($findOrCreateContractInput: FindOrCreateContractInput) {
  findOrCreateContract(findOrCreateContractInput: $findOrCreateContractInput) {
    signedUrl
  }
}`;

export const getContractSignedUrl = `query getContractSignedUrl($getContractSignedUrlInput: GetContractSignedUrlInput) {
  getContractSignedUrl(getContractSignedUrlInput: $getContractSignedUrlInput) {
    signedUrl
  }
}`;

export const getCustomerLicenseRequests = `query getCustomerLicenseRequests($getCustomerLicenseRequestsInput: GetCustomerLicenseRequestsInput) {
  getCustomerLicenseRequests(getCustomerLicenseRequestsInput: $getCustomerLicenseRequestsInput) {
    data {
      id
      title
      workType
      workLicenseTypes {
        id
        licenseType {
          id
          name
        }
      }
      licenseRequests {
        id
        status
        workCopyNumber
        decisionDate
        project {
          id
          name
        }
        licenseType {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
    selectedProject
  }
}`;

export const getMyCart = `query getMyCart($getMyCartInput: GetMyCartInput) {
  getMyCart(getMyCartInput: $getMyCartInput) {
    id
    title
    workType
    licenseRequests {
      id
      requestedDate
      signedByUser
      totalFee
      workCopyNumber
      contractKey
      isBatched
      batchedLicenseRequests {
        id
      }
      licenseType {
        id
        name
        songMaster
        commercial
      }
      project {
        id
        name
      }
    }
  }
}`;

export const getAllLicenseRequests = `query getAllLicenseRequests($getAllLicenseRequestsInput: GetAllLicenseRequestsInput) {
  getAllLicenseRequests(getAllLicenseRequestsInput: $getAllLicenseRequestsInput) {
    data {
      id
      title
      workType
      workLicenseTypes {
        id
        licenseType {
          id
          name
        }
      }
      licenseRequests {
        id
        status
        workCopyNumber
        decisionDate
        requestedDate
        voidedAt
        opened
        project {
          id
          name
        }
        licenseType {
          id
          commercial
          name
          licenseTypeQuestions {
            id
            question
            questionName
            autoPopulated
            triggersReview
            triggersReviewAnswer
            required
            requiredDependencies
            answerType
            hiddenUnless
            answerOptions
            ordering
            internal
            calculation
            licenseTypeAnswers {
              id
              answer
              licenseTypeQuestionId
              licenseRequestId
              createdById
            }
          }
        }
        user {
          id
          firstName
          lastName
          organization {
            id
            name
            organizationType
          }
        }
      }
    }
    total
    activeTotal
    pendingTotal
    updateMadeTotal
  }
}`;

export const getAllOrganizations = `query getAllOrganizations {
  getAllOrganizations {
    id
    name
    organizationType
  }
}`;

export const getLicenseRequestDetails = `query getLicenseRequestDetails($getLicenseRequestDetailsInput: GetLicenseRequestDetailsInput) {
  getLicenseRequestDetails(getLicenseRequestDetailsInput: $getLicenseRequestDetailsInput) {
    id
    status
    decisionDate
    requestedDate
    signedByUser
    signedByIntegrity
    updateReason
    batchContract
    contract
    approvedAt
    updateRequestedAt
    opened
    voidReason
    voidedAt
    adminNotes
    approvedBy {
      firstName
      lastName
    }
    updateRequestedBy {
      id
      firstName
      lastName
    }
    batchedLicenseRequests {
      id
      contractKey
      batchContract
      licenseType {
        licenseTypeQuestions {
          id
          questionName
          licenseTypeAnswers {
            id
            answer
          }
        }
      }
    }
    project {
      id
      name
    }
    stripeOrder {
      isPaid
      isComplete
    }
    user {
      id
      firstName
      lastName
      email
      organization {
        id
        name
        organizationType
        email
        address1
        address2
        city
        state
        zip
        town
        county
        postcode
      }
    }
    licenseType {
      id
      name
      commercial
      songMaster
      location
      contract
      licenseTypeQuestions {
        id
        question
        questionName
        autoPopulated
        triggersReview
        triggersReviewAnswer
        required
        requiredDependencies
        answerType
        hiddenUnless
        answerOptions
        ordering
        internal
        calculation
        licenseTypeAnswers {
          id
          answer
          licenseTypeQuestionId
          licenseRequestId
          createdById
          answerAttachments {
            id
            fileName
          }
        }
      }
    }
    work {
      id
      title
      workType
      totalImPercentControl
      openPlayId
      musicMaestroId
      writer1
      writer2
      writer3
      writer4
      writer5
      writer6
      writer7
      writer8
      writer9
      publisher1
      publisher2
      publisher3
      publisher4
      publisher5
      publisher6
      publisher7
      publisher8
      publisher9
      publisher10
      publisher11
      publisher12
      publisher13
      publisher14
      publisher15
      publisher16
      publisher17
      publisher18
      publisher19
      publisher20
      publisher21
      publisher22
      publisher23
      publisher24
      publisher25
      publisher26
      publisher27
      trackArtistName
      albumName
      releaseArtistName
      songVersion
      ccliNumber
      copyrightNotice
      isrcNumber
    }
  }
}`;

export const getRetrieveAttachmentSignedUrl = `query getRetrieveAttachmentSignedUrl($getRetrieveAttachmentSignedUrlInput: GetRetrieveAttachmentSignedUrlInput) {
  getRetrieveAttachmentSignedUrl(getRetrieveAttachmentSignedUrlInput: $getRetrieveAttachmentSignedUrlInput) {
    downloadUrl
  }
}`;

export const getMyLicenseRequestDetails = `query getMyLicenseRequestDetails($getLicenseRequestDetailsInput: GetLicenseRequestDetailsInput) {
  getMyLicenseRequestDetails(getLicenseRequestDetailsInput: $getLicenseRequestDetailsInput) {
    id
    status
    decisionDate
    requestedDate
    updateReason
    project {
      id
      name
    }
    licenseType {
      id
      name
      commercial
      songMaster
      licenseTypeQuestions {
        id
        question
        questionName
        autoPopulated
        triggersReview
        triggersReviewAnswer
        required
        requiredDependencies
        answerType
        hiddenUnless
        answerOptions
        ordering
        internal
        calculation
        licenseTypeAnswers {
          id
          answer
          licenseTypeQuestionId
          licenseRequestId
          createdById
          answerAttachments {
            id
            fileName
          }
        }
      }
    }
    work {
      id
      title
      workType
    }
  }
}`;

export const getLicenseRequests = `query getLicenseRequests($getLicenseRequestsInput: GetLicenseRequestsInput) {
  getLicenseRequests(getLicenseRequestsInput: $getLicenseRequestsInput) {
    data {
    id
    title
    workType
    workLicenseTypes {
      id
      licenseType {
        id
        name
      }
    }
    licenseRequests {
      id
      status
      workCopyNumber
      decisionDate
      requestedDate
      updateReason
      opened
      project {
        id
        name
      }
      licenseType {
        id
        name
        commercial
        helpText
        licenseTypeQuestions {
          id
          question
          questionName
          autoPopulated
          triggersReview
          triggersReviewAnswer
          required
          requiredDependencies
          answerType
          hiddenUnless
          answerOptions
          ordering
          internal
          calculation
          licenseTypeId
          licenseTypeAnswers {
            id
            answer
            licenseTypeQuestionId
            licenseRequestId
            createdById
            answerAttachments {
              id
              fileName
            }
          }
        }
      }
    }
  }
  total
  approvedTotal
  deniedTotal
  updateNeededTotal
  expiredTotal
  }
}`;

export const getProductLicenses = `query getProductLicenses($getProductLicensesInput: GetProductLicensesInput) {
  getProductLicenses(getProductLicensesInput: $getProductLicensesInput) {
    data {
      id
      status
      requestedDate
        project {
          id
          name
        }
        licenseType {
          id
          name
        }
        user {
          id
          firstName
          lastName
          organization {
            id
            name
            organizationType
          }
        }
    }
    total
  }
}`;

// export const getProductLicenses = `query getProductLicenses($getProductLicensesInput: GetProductLicensesInput) {
//   getProductLicenses(getProductLicensesInput: $getProductLicensesInput) {
//     data {
//       id
//     }
//     total
//   }
// }`;

export const getStripePaymentMethod = `query getStripePaymentMethod {
  getStripePaymentMethod {
    id
    lastFour
  }
}`;

export const getIncompleteOrder = `query getIncompleteOrder {
  getIncompleteOrder {
    id
    isComplete
    licenseRequests {
      id
      status
      workCopyNumber
      decisionDate
      requestedDate
      updateReason
      signedByUser
      project {
        id
        name
      }
      licenseType {
        id
        name
        commercial
        helpText
        licenseTypeQuestions {
          id
          question
          questionName
          autoPopulated
          triggersReview
          triggersReviewAnswer
          required
          requiredDependencies
          answerType
          hiddenUnless
          answerOptions
          ordering
          internal
          calculation
          licenseTypeId
          licenseTypeAnswers {
            id
            answer
            licenseTypeQuestionId
            licenseRequestId
            createdById
            answerAttachments {
              id
              fileName
            }
          }
        }
      }
    }
  }
}`;

export const getQueueSummary = `query getQueueSummary {
  getQueueSummary {
    items {
      musicMaestroId
      openPlayId
    }
    size
    hasLicense
    hasApprovedLicense
  }
}`;

export const getAllUsersWithLicenseRequests = `query getAllUsersWithLicenseRequests {
  getAllUsersWithLicenseRequests {
    id
    username
    firstName
    lastName
  }
}`;

export const getAllCustomerAccounts = `query getAllCustomerAccounts($getAllCustomerAccountsInput: GetAllCustomerAccountsInput) {
  getAllCustomerAccounts(getAllCustomerAccountsInput: $getAllCustomerAccountsInput) {
    data {
      id
      firstName
      lastName
      username
      email
      phone
      city
      state
      town
      county
      sub
      status
      organization {
        id
        name
        status
        organizationType
        region
        licenseRequestCount
      }
      role {
        id
        name
      }
    }
    total
  }
}`;

export const getPendingAccountsSummary = `query getPendingAccountsSummary {
  getPendingAccountsSummary {
    pendingAccounts
    pendingOrgChangeRequests
    pendingDeactivationRequests
    hasLicense
  }
}`;

export const getOrgChangeRequests = `query getOrgChangeRequests {
  getOrgChangeRequests {
    id
    organization {
      id
      name
      city
      state
      town
      county
      status
      organizationType
      region
    }
    fieldname
    value
    status
    createdBy {
      id
      firstName
      lastName
      sub
    }
  }
}`;

export const getAccount = `query getAccount($sub: String) {
  getAccount(sub: $sub) {
    id
    sub
    firstName
    lastName
    username
    email
    phone
    address1
    address2
    city
    state
    zip
    town
    county
    postcode
    region
    status
    organization {
      id
      name
      status
      organizationType
      address1
      address2
      city
      state
      zip
      town
      county
      postcode
      landLine
      email
      stripePaymentMethodId
      region
      licNoDistribution
      licNumberOfUnits
      licOneTimePayment
      licDefinitions
      licUseCases
    }
  }
}`;

export const getAllProjects = `query getAllProjects($getAllProjectsInput: GetAllProjectsInput) {
  getAllProjects(getAllProjectsInput: $getAllProjectsInput) {
    id
    name
    active
  }
}`;

export const getCustomerProjects = `query getCustomerProjects($getCustomerProjectsInput: GetCustomerProjectsInput) {
  getCustomerProjects(getCustomerProjectsInput: $getCustomerProjectsInput) {
    id
    name
  }
}`;

export const getProjects = `query getProjects {
  getProjects {
    id
    name
    active
  }
}`;

export const getUpload = `query getUpload($id: Int) {
  getUpload(id: $id) {
    id
    rowCount
    removeCount
    workCount
    removeWorkCount
  }
}`;

export const getQueue = `query getQueue($getQueueInput: GetQueueInput) {
  getQueue(getQueueInput: $getQueueInput) {
    data {
      id
      title
      workType
      musicMaestroId
      openPlayId
      songTitle
      trackName
      licenseRequestCount
      licenseRequests {
        id
        status
        project {
          id
          name
        }
        licenseType {
          id
          name
        }
      }
      workLicenseTypes {
        id
        licenseType {
          id
          name
          description
          songMaster
        }
      }
    }
    total
  }
}`;

export const getLicenseType = `query getLicenseType($id: Int) {
  getLicenseType(id: $id) {
    id
    name
    songMaster
    commercial
    location
    contract
    licenseTypeQuestions {
      id
      question
      questionName
      autoPopulated
      answerType
      internal
    }
  }
}`;

export const listAllLicenseTypes = `query ListAllLicenseTypes {
  listAllLicenseTypes {
    id
    name
    songMaster
    commercial
    location
    createdBy {
      id
      sub
      firstName
      lastName
    }
  }
}`;

export const publicSearchWorks = `query SearchWorks {
  publicSearchWorks(searchInput: [searchInput]) {
    hits {
      title
      artist
      workType
      songTitle
      ccliNumber
      trackName
      totalImPercentControl
      territoryControlled
      region
      writer1
      writer2
      writer3
      writer4
      writer5
      writer6
      writer7
      writer8
      writer9
      publisher1
      publisher2
      publisher3
      publisher4
      publisher5
      publisher6
      publisher7
      publisher8
      publisher9
      publisher10
      publisher11
      publisher12
      publisher13
      publisher14
      publisher15
      publisher16
      publisher17
      publisher18
      publisher19
      publisher20
      publisher21
      publisher22
      publisher23
      publisher24
      publisher25
      publisher26
      publisher27
      albumName
      releaseArtistName
      isrcNumber
      _score
      trackArtistName
      musicMaestroId
      openPlayId
      highlight {
        songTitle
        trackName
        albumName
        akas
        releaseArtistName
        trackArtistName
        projectName
        writer1
        writer2
        writer3
        writer4
        writer5
        writer6
        writer7
        writer8
        writer9
        publisher1
        publisher2
        publisher3
        publisher4
        publisher5
        publisher6
        publisher7
        publisher8
        publisher9
        publisher10
        title
        artist
      }
    }
    took
    timed_out
    total {
      value
      relation
    }
  }
}`;

export const publicGetWork = `query GetWork {
  publicGetWork(getWorkInput:{id: [id], musicMaestroId: [musicMaestroId], openPlayId: [openPlayId], region: [region], version: [version]}) {
    id
    title
    workType
    akas
    createdAt
    updatedAt
    deletedAt
    songTitle
    ccliNumber
    totalImPercentControl
    territoryControlled
    region
    copyrightNotice
    writer1
    writer2
    writer3
    writer4
    writer5
    writer6
    writer7
    writer8
    writer9
    publisher1
    publisher2
    publisher3
    publisher4
    publisher5
    publisher6
    publisher7
    publisher8
    publisher9
    publisher10
    publisher11
    publisher12
    publisher13
    publisher14
    publisher15
    publisher16
    publisher17
    publisher18
    publisher19
    publisher20
    publisher21
    publisher22
    publisher23
    publisher24
    publisher25
    publisher26
    publisher27
    trackName
    albumName
    primaryReleaseDate
    songVersion
    releaseArtistName
    projectName
    isrcNumber
    discNumber
    sideNumber
    trackNumber
    trackArtistName
    artist
    musicMaestroId
    openPlayId
    createdBy {
      id
      firstName
      lastName
    }
    updatedBy {
      id
      firstName
      lastName
    }
    licenseRequests {
      id
      status
      requestedDate
      decisionDate
      licenseType {
        id
        name
        commercial
        songMaster
      }
    }
    workLicenseTypes {
      licenseType {
        id
        name
        commercial
        location
        songMaster
        __typename
      }
    }
  }
}`;

export const protectedGetWork = publicGetWork
  .replace("[id]", "$id")
  .replace("[musicMaestroId]", "$musicMaestroId")
  .replace("[openPlayId]", "$openPlayId")
  .replace("[region]", "$region")
  .replace("[version]", "$version")
  .replace(
    "query GetWork",
    "query GetWork ($id: Int, $musicMaestroId: String, $openPlayId: String, $region: String, $version: Int)"
  )
  .replace("publicGetWork", "protectedGetWork");

export const listUsers = `query listUsers {
  listUsers {
    id
    sub
    firstName
    lastName
    email
    username
    status
    role {
      name
    }
  }
}`;

export const getUserFromOrgAdmin = `query getUserFromOrgAdmin($sub: String!) {
  getUserFromOrgAdmin(sub: $sub) {
    id
    sub
    firstName
    lastName
    email
    username
    role {
      id
      name
    }
    permissions {
      id
      name
    }
  }
}`;

export const listPermissions = `query listPermissions {
  listPermissions {
    id
    slug
    name
  }
}`;

export const listRoles = `query listRoles {
  listRoles {
    id
    slug
    name
  }
}`;

export const getDeactivationRequests = `query getDeactivationRequests {
  getDeactivationRequests {
    id
    firstName
    lastName
    username
    email
    phone
    city
    state
    town
    county
    sub
    status
    organization {
      id
      name
      organizationType
      address1
      address2
      city
      state
      zip
      town
      county
      postcode
      status
      region
      deactivationRequestedById
      deactivationRequestedAt
    }
  }
}`;

export const downloadTransactionInformation = `query downloadTransactionInformation($downloadTransactionInformationInput: DownloadTransactionInformationInput) {
  downloadTransactionInformation(downloadTransactionInformationInput:$downloadTransactionInformationInput) {
    licenseRequestId
    title
    artist
    songwriters
    musicMaestroId
    openPlayId
    commercial
    customerName
    organizationName
    totalFee
    advance
    vat
    subtotal
    date
    units
    licenseType
    paidDate
    productTitle
    isrc
  }
}`;

export const downloadBatchedContracts = `query downloadBatchedContracts ($downloadBatchedContractsInput: DownloadTransactionInformationInput) {
  downloadBatchedContracts (downloadBatchedContractsInput: $downloadBatchedContractsInput) {
    location
  }
}`;

export const getReportDownloadLog = `query getReportDownloadLog ($getReportLogInput: GetReportLogInput) {
  getReportDownloadLog (getReportLogInput: $getReportLogInput) {
    log {
      user {
        username
      }
      startDate
      endDate
      songsOrMasters
      orgType
      createdAt
      organization {
        name
      }
      project {
        name
      }
      zipFileLocation
      downloadType
    }
    rows
  }
}`;
