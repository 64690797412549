import { Project } from "integrity-owls-logic/dist";
import { SET_PROJECTS } from "../../constants";
import { ReduxProjectState } from "../../interfaces/Shared";

const INITIAL_STATE: ReduxProjectState = {
  projects: []
};

interface ProjectAction {
  type: string;
  payload: Project[];
}

export default (
  state = INITIAL_STATE,
  action: ProjectAction
): ReduxProjectState => {
  switch (action.type) {
    case SET_PROJECTS:
      return { projects: action.payload };
    default:
      return state;
  }
};
