import React, { createContext, useContext, useEffect, useState } from "react";
import { LicenseRequest, WorkLicenseType } from "integrity-owls-logic/dist";
import { useHistory } from "react-router";

interface WorkType {
  id: number;
  licenseRequests: LicenseRequest[];
  title: string;
  workLicenseTypes: WorkLicenseType[] | null;
  workType: string;
}

interface WorkListContextValue {
  workList: WorkType[];
  updateWorkList: (newValue: WorkType[], isAdmin: boolean) => void;
  setIndices: (workIndex: number, requestIndex: number) => void;
  hasNextLicenseRequest: () => boolean;
  hasPreviousLicenseRequest: () => boolean;
  moveToNextRequest: () => void;
  moveToPreviousRequest: () => void;
}

const WorkListContext = createContext<WorkListContextValue | undefined>(
  undefined
);

export const WorkListProvider: React.FC = ({ children }) => {
  const [workList, setWorkList] = useState<WorkType[]>([]);
  const [currWorkIndex, setCurrWorkIndex] = useState<number>(-1);
  const [currRequestIndex, setCurrRequestIndex] = useState<number>(-1);
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState<boolean>(true);

  const updateWorkList = (newValue: WorkType[], isAdmin = true) => {
    setIsAdmin(isAdmin);
    setWorkList(newValue);
  };

  // Set the current work and request indices with bounds checks
  const setIndices = (workIndex: number, requestIndex: number) => {
    if (workIndex >= 0 && workIndex < workList.length) {
      setCurrWorkIndex(workIndex);
    } else {
      console.error(`Invalid work index: ${workIndex}`);
      return;
    }

    if (
      workIndex >= 0 &&
      workIndex < workList.length &&
      requestIndex >= 0 &&
      requestIndex < workList[workIndex].licenseRequests.length
    ) {
      setCurrRequestIndex(requestIndex);
    } else {
      console.error(`Invalid request index: ${requestIndex}`);
    }
  };

  const hasNextLicenseRequest = (): boolean => {
    if (
      currWorkIndex >= 0 &&
      currWorkIndex < workList.length &&
      currRequestIndex >= 0 &&
      currRequestIndex < workList[currWorkIndex].licenseRequests.length - 1
    ) {
      return true;
    }

    if (currWorkIndex < workList.length - 1) {
      return true;
    }
    return false;
  };

  const hasPreviousLicenseRequest = (): boolean => {
    if (
      currWorkIndex >= 0 &&
      currWorkIndex < workList.length &&
      currRequestIndex > 0
    ) {
      return true;
    }

    if (currWorkIndex > 0) {
      return true;
    }

    return false;
  };

  const moveToNextRequest = () => {
    if (hasNextLicenseRequest()) {
      let wIndex: number = currWorkIndex;
      let rIndex: number;
      if (
        currRequestIndex <
        workList[currWorkIndex].licenseRequests.length - 1
      ) {
        rIndex = currRequestIndex + 1;
        setCurrRequestIndex(currRequestIndex + 1);
      } else {
        wIndex = currWorkIndex + 1;
        rIndex = 0;
        setCurrWorkIndex(wIndex);
        setCurrRequestIndex(0);
      }
      if (isAdmin) {
        history.push(
          `/licenses/details/${workList[wIndex].licenseRequests[rIndex].id}`
        );
      } else {
        const license = workList[wIndex].licenseRequests[rIndex];
        history.push(
          `/license-details/${workList[wIndex].licenseRequests[rIndex].id}`,
          {
            license
          }
        );
      }
    }
  };

  const moveToPreviousRequest = () => {
    if (hasPreviousLicenseRequest()) {
      let wIndex: number = currWorkIndex;
      let rIndex: number;
      if (currRequestIndex > 0) {
        rIndex = currRequestIndex - 1;
        setCurrRequestIndex(rIndex);
      } else {
        rIndex = workList[currWorkIndex - 1].licenseRequests.length - 1;
        wIndex = currWorkIndex - 1;
        setCurrWorkIndex(wIndex);
        setCurrRequestIndex(rIndex);
      }
      if (isAdmin) {
        history.push(
          `/licenses/details/${workList[wIndex].licenseRequests[rIndex].id}`
        );
      } else {
        const license = workList[wIndex].licenseRequests[rIndex];
        history.push(
          `/license-details/${workList[wIndex].licenseRequests[rIndex].id}`,
          {
            license
          }
        );
      }
    }
  };

  const contextValue: WorkListContextValue = {
    workList,
    updateWorkList,
    setIndices,
    hasNextLicenseRequest,
    hasPreviousLicenseRequest,
    moveToNextRequest,
    moveToPreviousRequest
  };

  return (
    <WorkListContext.Provider value={contextValue}>
      {children}
    </WorkListContext.Provider>
  );
};

export const useWorkListContext = () => {
  const context = useContext(WorkListContext);
  if (context === undefined) {
    throw new Error(
      "useWorkListContext must be used within a WorkListProvider"
    );
  }
  return context;
};
