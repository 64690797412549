import Resource from "./Resource";
import { IAttributeType } from "./interfaces/IAttribute";

export class User extends Resource {
  static $name = "User";
  static $listGraphQLResponseShape = "PK firstName lastName";
  static columns = [
    { title: "PK", field: "PK" },
    { title: "First Name", field: "firstName" },
    { title: "Last Name", field: "lastName" },
    { title: "Organization", field: "organizationName" }
  ];

  static rowTransformer(rawRecord: any) {
    if (!rawRecord) {
      return {};
    }

    return {
      PK: rawRecord.PK,
      SK: rawRecord.SK,
      firstName: rawRecord.firstName,
      lastName: rawRecord.lastName,
      rawRecord
    };
  }

  protected $getGraphQLResponseShape = "PK firstName lastName email";
  protected $defaultAttributes = [
    {
      name: "firstName",
      displayName: "First Name",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "lastName",
      displayName: "Last Name",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "email",
      displayName: "Email",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "address1",
      displayName: "Address1",
      type: IAttributeType.Text,
      required: true
    },
    {
      name: "address2",
      displayName: "Address2",
      type: IAttributeType.Text,
      required: false
    }
  ];
}

export default User;
