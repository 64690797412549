import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import {
  getAllCustomerAccounts,
  getPendingAccountsSummary,
  getAccount,
  getOrgChangeRequests,
  getDeactivationRequests
} from "./graphql/queries";
import Amplify from "aws-amplify";
import { default as config } from "../config";
import {
  approveAccount,
  denyAccount,
  deactivateUser,
  reactivateUser,
  deactivateOrganization,
  deleteOrganization,
  reactivateOrganization,
  deactivateUserFromMyOrganization,
  reactivateUserFromMyOrganization,
  approveOrgChangeRequest,
  denyOrgChangeRequest,
  approveDeactivationRequest,
  denyDeactivationRequest,
  changeOrganizationType
} from "./graphql/mutations";
import { GetAllCustomerAccountsInput } from "../interfaces/Accounts";
import { PendingAccountsSummary } from "integrity-owls-logic/dist/interfaces/Account";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

interface GetPendingAccountsSummaryReturn {
  data: {
    getPendingAccountsSummary: PendingAccountsSummary;
  };
}

class AccountService extends BaseService {
  async getAllCustomerAccounts(
    getAllCustomerAccountsInput: GetAllCustomerAccountsInput
  ) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getAllCustomerAccounts, {
          getAllCustomerAccountsInput
        })
      )) as any;
      // console.log('got customer accounts', data);
      return data.getAllCustomerAccounts;
    } catch (err) {
      console.log("Error getting all customer accounts", err);
    }
  }

  async getPendingAccountsSummary() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getPendingAccountsSummary, {})
      )) as GetPendingAccountsSummaryReturn;
      return data.getPendingAccountsSummary;
    } catch (err) {
      console.error("Error getting getPendingAccountsSummary", err);
    }
  }

  async getAccount(accountSub: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getAccount, { sub: accountSub })
      )) as any;
      console.log({ data });
      return data.getAccount;
    } catch (err) {
      console.error("Error getting account", err);
    }
  }

  async approveAccount(accountSub: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(approveAccount, { sub: accountSub })
      )) as any;
      console.log({ data });
      return data.approveAccount;
    } catch (err) {
      console.error("Error approving account", err);
      throw err;
    }
  }

  async denyAccount(accountSub: string, reason: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(denyAccount, { sub: accountSub, reason })
      )) as any;
      console.log({ data });
      return data.denyAccount;
    } catch (err) {
      console.error("Error denying account", err);
      throw err;
    }
  }

  async deactivateUser(accountSub: string, reason: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(deactivateUser, { sub: accountSub, reason })
      )) as any;
      console.log({ data });
      return data.deactivateUser;
    } catch (err) {
      console.error("Error deactivating user", err);
      throw err;
    }
  }

  async reactivateUser(accountSub: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(reactivateUser, { sub: accountSub })
      )) as any;
      console.log({ data });
      return data.reactivateUser;
    } catch (err) {
      console.error("Error reactivating user", err);
      throw err;
    }
  }

  async deactivateOrganization(id: number, reason: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(deactivateOrganization, { id, reason })
      )) as any;
      console.log({ data });
      return data.deactivateOrganization;
    } catch (err) {
      console.error("Error deactivating organization", err);
      throw err;
    }
  }

  async deleteOrganization(id: number) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(deleteOrganization, { id })
      )) as any;
      console.log({ data });
      return data.deleteOrganization;
    } catch (err) {
      console.error("Error deleting organization", err);
      throw err;
    }
  }

  async reactivateOrganization(id: number) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(reactivateOrganization, { id })
      )) as any;
      console.log({ data });
      return data.reactivateOrganization;
    } catch (err) {
      console.error("Error reactivating organization", err);
      throw err;
    }
  }

  async deactivateUserFromMyOrganization(sub: string, reason: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(deactivateUserFromMyOrganization, { sub, reason })
      )) as any;
      console.log({ data });
      return data.deactivateUserFromMyOrganization;
    } catch (err) {
      console.error("Error deactivating user", err);
      throw err;
    }
  }

  async reactivateUserFromMyOrganization(sub: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(reactivateUserFromMyOrganization, { sub })
      )) as any;
      console.log({ data });
      return data.reactivateUserFromMyOrganization;
    } catch (err) {
      console.error("Error reactivating user", err);
      throw err;
    }
  }

  async getOrgChangeRequests() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getOrgChangeRequests)
      )) as any;
      console.log({ data });
      return data.getOrgChangeRequests;
    } catch (err) {
      console.error("Error getting OrgChangeRequests", err);
      throw err;
    }
  }

  async approveOrgChangeRequest(id: number) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(approveOrgChangeRequest, { id })
      )) as any;
      console.log({ data });
      return data.approveOrgChangeRequest;
    } catch (err) {
      console.error("error approving orgChangeRequest", err);
      throw err;
    }
  }

  async denyOrgChangeRequest(id: number, reason: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(denyOrgChangeRequest, { id, reason })
      )) as any;
      console.log({ data });
      return data.denyOrgChangeRequest;
    } catch (err) {
      console.error("error approving orgChangeRequest", err);
      throw err;
    }
  }

  async getDeactivationRequests() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getDeactivationRequests)
      )) as any;
      return data.getDeactivationRequests;
    } catch (err) {
      console.error("error getting deactivationRequests", err);
      throw err;
    }
  }

  async approveDeactivationRequest(id: number) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(approveDeactivationRequest, { id })
      )) as any;
      return data.approveDeactivationRequest;
    } catch (err) {
      console.error("error approving deactivation request");
      throw err;
    }
  }

  async denyDeactivationRequest(id: number, reason: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(denyDeactivationRequest, { id, reason })
      )) as any;
      return data.denyDeactivationRequest;
    } catch (err) {
      console.error("error denying deactivation request");
      throw err;
    }
  }

  async changeOrganizationType(sub: string, organizationType: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(changeOrganizationType, { sub, organizationType })
      )) as any;
      return data.changeOrganizationType;
    } catch (err) {
      console.error("error changing organization type");
      throw err;
    }
  }
}

export const accountService = new AccountService();
export default accountService;
