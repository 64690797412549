import { SET_PROFILE } from "../constants";
import { profileService } from "../services";

export const setProfile = (profile: any) => {
  return {
    type: SET_PROFILE,
    payload: profile
  };
};

export const updateProfile = (payload: any) => {
  return async (dispatch: any) => {
    try {
      const profile = await profileService.updateProfile(payload);
      if (!profile) {
        throw new Error("error updating profile");
      }
      dispatch(setProfile(profile));
    } catch (err) {
      console.log("Error: ", err);
    }
  };
};
