import { Work } from "integrity-owls-logic/dist";
import {
  SET_SEARCH_ALBUM_TITLE,
  SET_SEARCH_ARTIST,
  SET_SEARCH_TEXT,
  SET_SEARCH_PAGE,
  SET_SEARCH_PUBLISHER,
  SET_SEARCH_ROWS_PER_PAGE,
  SET_SEARCH_SHOW_ONLY_SONGS,
  SET_SEARCH_SHOW_ONLY_MASTERS,
  SET_SEARCH_SONG_TITLE,
  SET_SEARCH_SONG_WRITER,
  SET_SEARCH_SORT_BY,
  SET_SEARCH_SORT_DIRECTION,
  SET_SEARCH_SELECTED_WORK
} from "../../constants";
import { ReduxSearchState } from "../../interfaces/Shared";

const INITIAL_STATE: ReduxSearchState = {
  artist: "",
  page: 0,
  publisher: "",
  rowsPerPage: 10,
  searchText: "",
  showOnlySongs: false,
  showOnlyMasters: false,
  songTitle: "",
  songWriter: "",
  sortBy: "_score",
  sortDirection: "desc",
  selectedWork: new Work(),
  albumTitle: ""
};

interface SearchAction {
  type: string;
  payload: any;
}

export default (
  state = INITIAL_STATE,
  action: SearchAction
): ReduxSearchState => {
  switch (action.type) {
    case SET_SEARCH_ALBUM_TITLE:
      return { ...state, albumTitle: action.payload };
    case SET_SEARCH_ARTIST:
      return { ...state, artist: action.payload };
    case SET_SEARCH_TEXT:
      return { ...state, searchText: action.payload };
    case SET_SEARCH_PAGE:
      return { ...state, page: action.payload };
    case SET_SEARCH_PUBLISHER:
      return { ...state, publisher: action.payload };
    case SET_SEARCH_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.payload };
    case SET_SEARCH_SHOW_ONLY_SONGS:
      return { ...state, showOnlySongs: action.payload };
    case SET_SEARCH_SHOW_ONLY_MASTERS:
      return { ...state, showOnlyMasters: action.payload };
    case SET_SEARCH_SONG_TITLE:
      return { ...state, songTitle: action.payload };
    case SET_SEARCH_SONG_WRITER:
      return { ...state, songWriter: action.payload };
    case SET_SEARCH_SORT_BY:
      return { ...state, sortBy: action.payload };
    case SET_SEARCH_SORT_DIRECTION:
      return { ...state, sortDirection: action.payload };
    case SET_SEARCH_SELECTED_WORK:
      return { ...state, selectedWork: action.payload };
    default:
      return state;
  }
};
