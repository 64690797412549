import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { push } from "connected-react-router";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { createStyles, withStyles } from "@material-ui/core";
import IResource from "../../resources/interfaces/IResource";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Location } from "history";
import {
  ActionType,
  IAttribute,
  IAttributeType,
  ICollectionAttribute
} from "../../resources/interfaces/IAttribute";

const styles = (theme: any) =>
  createStyles({
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: theme.spacing(0.25)
    },
    normalWeight: {
      fontWeight: theme.typography.fontWeightRegular
    },
    mediumWeight: {
      fontWeight: theme.typography.fontWeightMedium
    },
    progressContainer: {
      textAlign: "center"
    },
    hide: {
      display: "none"
    }
  });

interface Props extends RouteComponentProps<any> {
  resource: IResource;
  listReturnShape?: string;
  push?(route: string): void;
  match: any;
}

interface State {
  loading: boolean;
  error: Error | null;
  attrs: IAttribute[];
  alertOpen: boolean;
  showResource: IResource | null;
}

type ResourceClass = {
  new (record: any, partialRecord: boolean): IResource;
  columns: { title: string; field: string }[];
  rowTransformer(record: any): any;
  all(
    responseShape: string | undefined,
    limit?: number,
    nextToken?: string | null
  ): Promise<any[]>;
};

class ResourceView extends React.Component<Props, State> {
  protected resource: IResource;
  protected resourceClass: ResourceClass;
  protected resourceName: string;

  state: State = {
    loading: true,
    error: null,
    attrs: [],
    alertOpen: false,
    showResource: null
  };

  constructor(props: Props) {
    super(props);

    const { resource } = props;

    this.resource = resource;
    this.resourceName = capitalize(
      pluralize((resource.constructor as any).$name)
    );
    this.resourceClass = (resource.constructor as any) as ResourceClass;
    this.updateStateFromRoute = this.updateStateFromRoute.bind(this);
    this.loadResource = this.loadResource.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.buildForm = this.buildForm.bind(this);
    this.buildAttrs = this.buildAttrs.bind(this);
  }

  protected updateStateFromRoute(locationToUse?: Location) {
    const { match } = this.props;
    const location = locationToUse ? locationToUse : this.props.location;

    const showResource = new this.resourceClass(
      { rawRecord: { id: location.pathname.replace(`${match.url}/`, "") } },
      true
    );

    this.setState({ showResource: showResource });
  }

  loadResource = async () => {
    if (!this.state) {
      return;
    }
    const { showResource } = this.state;
    this.setState({ loading: true });

    if (!showResource) {
      return;
    }
    try {
      await showResource.initialize();
      this.setState({ attrs: showResource.attributes });
    } catch (error) {
      console.log("GOT ERROR DURING RESOURCE INIT", error);
      this.setState({ error: error as any });
    } finally {
      this.setState({ loading: false });
    }
  };

  async componentDidMount() {
    await this.updateStateFromRoute();
    this.loadResource();
  }

  handleFieldChange = (attr: IAttribute) => (event: any) => {
    if (attr.type === IAttributeType.Select) {
      const colAttr = attr as ICollectionAttribute;
      if (colAttr.multiple) {
        colAttr.arrayValue = event.target.value;
      } else {
        colAttr.value = event.target.value;
      }

      attr.error = attr.required && !colAttr.arrayValue && !colAttr.value;
    } else {
      attr.value = event.target.value;
      attr.error = attr.required && !attr.value;
    }

    const attrs = this.state.attrs.map((existingAttr: IAttribute) => {
      if (existingAttr.name === attr.name) {
        return attr;
      }

      return existingAttr;
    });

    this.setState({ attrs });
  };

  buildForm() {
    return (
      <Grid container spacing={10}>
        {this.buildAttrs()}
      </Grid>
    );
  }

  buildAttrs() {
    if (!this.state) {
      return <span>There is no resource associated with this form.</span>;
    }

    const { attrs } = this.state;

    if (!attrs) {
      return <span>There are no attributes associated with this form.</span>;
    }
    return attrs
      .filter((attr: IAttribute) => {
        if (attr.visible) {
          if (this.props.resource && this.props.resource.PK) {
            return attr.visible.includes(ActionType.UPDATE);
          } else {
            return attr.visible.includes(ActionType.CREATE);
          }
        }
        return true;
      })
      .map((attr: IAttribute, i: number) => {
        const readOnly = attr.readOnly
          ? this.props.resource && this.props.resource.PK
            ? attr.readOnly.includes(ActionType.UPDATE)
            : attr.readOnly.includes(ActionType.CREATE)
          : false;
        return (
          <Grid item xs={12} sm={attr.columnWidth || 12} key={attr.name}>
            <TextField
              margin="dense"
              id={attr.name}
              label={attr.displayName}
              type={attr.type}
              value={attr.value || ""}
              error={attr.error}
              disabled={readOnly}
              onChange={this.handleFieldChange(attr)}
              required={attr.required && !readOnly}
              multiline={attr.type === IAttributeType.TextArea}
              helperText={attr.helpText}
              fullWidth
            />
          </Grid>
        );
      });
  }

  render() {
    const { resource } = this.props;
    return (
      <div>
        {resource && (resource.constructor as any).$name}
        <Grid container spacing={10}>
          {this.buildForm()}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(
  withRouter(
    connect<Props>(null, { push })(ResourceView)
  )
);
