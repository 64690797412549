import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import {
  addProjectToLicenseRequests,
  approveLicenseRequest,
  updateOpened,
  createLicenseRequest,
  createLicenseRequests,
  denyLicenseRequest,
  deleteLicenseRequest,
  duplicateLicenseRequests,
  startEnqueuedLicenseRequests,
  requireLicenseRequestUpdates,
  submitEnqueuedLicenseRequests,
  upsertLicenseTypeAnswers,
  updateContract,
  updateRates,
  getAnswerSignedUrl,
  deleteAnswerAttachment,
  voidLicenseRequest,
  setAdminNotes
} from "./graphql/mutations";
import {
  downloadTransactionInformation,
  getAllLicenseRequests,
  getAllUsersWithLicenseRequests,
  getLicenseRequestDetails,
  getCustomerLicenseRequests,
  getMyLicenseRequestDetails,
  getLicenseRequests,
  getQueue,
  getQueueSummary,
  getReportDownloadLog,
  downloadBatchedContracts,
  getRetrieveAttachmentSignedUrl
} from "./graphql/queries";

import Amplify from "aws-amplify";
import { default as config } from "../config";
import {
  AnswerAttachment,
  LicenseTypeAnswer
} from "integrity-owls-logic/dist/models";
import {
  GetReportLogInput,
  GetReportLogReturn
} from "integrity-owls-logic/src/interfaces";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

interface GetReportDownloadLogGraphQl {
  data: {
    getReportDownloadLog: GetReportLogReturn;
  };
}

export interface GetAnswerSignedUrlInput {
  licenseTypeQuestionId: number;
  licenseRequestId: number;
  fileName: string;
}

export interface DeleteAnswerAttachmentInput {
  id: number;
}

interface GetAnswerSignedUrlResponse {
  data: {
    getAnswerSignedUrl: AnswerAttachment;
  };
}

interface DeleteAnswerAttachmentResponse {
  data: {
    deleteAnswerAttachment: AnswerAttachment;
  };
}

interface GetRetrieveAttachmentSignedUrlInput {
  answerAttachmentId: number;
}

interface GetRetrieveAttachmentSignedUrlReturn {
  data: {
    getRetrieveAttachmentSignedUrl: {
      downloadUrl: string;
    };
  };
}

interface VoidLicenseRequestInput {
  licenseRequestId: number;
  reason: string;
}

class LicenseRequestService extends BaseService {
  async getRetrieveAttachmentSignedUrl(
    getRetrieveAttachmentSignedUrlInput: GetRetrieveAttachmentSignedUrlInput
  ) {
    console.log("getRetrieveAttachmentSignedUrl", {
      getRetrieveAttachmentSignedUrlInput
    });
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getRetrieveAttachmentSignedUrl, {
          getRetrieveAttachmentSignedUrlInput
        })
      )) as GetRetrieveAttachmentSignedUrlReturn;
      return data.getRetrieveAttachmentSignedUrl.downloadUrl;
    } catch (err) {
      console.error("Error retrieving signed Url", err);
    }
  }

  async approveLicenseRequest(approveLicenseRequestInput: {
    licenseRequestId: string;
    answers: [LicenseTypeAnswer];
    contract: string;
    batchContractKey: string;
  }) {
    console.log("approveLicenseRequestInput", approveLicenseRequestInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(approveLicenseRequest, { approveLicenseRequestInput })
      )) as any;
      console.log("data", data);
    } catch (err) {
      console.error("Error approving request", err);
    }
  }

  async setAdminNotes(setAdminNotesInput: {
    licenseRequestId: string;
    adminNotes: string;
  }) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(setAdminNotes, { setAdminNotesInput })
      )) as any;
      console.log("data Admin Notes", data);
    } catch (err) {
      console.error("Error approving request", err);
    }
  }

  async updateOpened(updateOpenedInput: {
    licenseRequestId: string;
    opened: boolean;
  }) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(updateOpened, { updateOpenedInput })
      )) as any;
      console.log("data", data);
    } catch (err) {
      console.error("Error approving request", err);
    }
  }

  async addProjectToLicenses(projectId: number, licenseRequestIds: [number]) {
    const addProjectToLicenseRequestsInput = {
      projectId,
      licenseRequestIds
    };
    console.log(
      "addProjectToLicenseRequestsInput",
      addProjectToLicenseRequestsInput
    );
    try {
      const { data } = (await API.graphql(
        graphqlOperation(addProjectToLicenseRequests, {
          addProjectToLicenseRequestsInput
        })
      )) as any;
      console.log("add project to licenses data", data);
      return data;
    } catch (err) {
      console.error("Error adding project to licenses", err);
    }
  }

  async createLicenseRequests(
    createLicenseRequestsInput: { workId: number; licenseTypeIds: [number] }[]
  ) {
    console.log("createLicenseRequestsInput", createLicenseRequestsInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(createLicenseRequests, {
          createLicenseRequestsInput: {
            items: createLicenseRequestsInput
          }
        })
      )) as any;
      console.log("created license requests", data);
      return data;
    } catch (err) {
      console.error("Error creating license requests", err);
    }
  }

  async denyLicenseRequest(denyLicenseRequestInput: {
    licenseRequestId: string;
  }) {
    console.log("denyLicenseRequestInput", denyLicenseRequestInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(denyLicenseRequest, { denyLicenseRequestInput })
      )) as any;
      console.log("data", data);
    } catch (err) {
      console.error("Error approving request", err);
    }
  }

  async getAllUsersWithLicenseRequests() {
    console.log("getting all users with license requests");
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getAllUsersWithLicenseRequests)
      )) as any;
      console.log("users with license requests data", data);
      return data.getAllUsersWithLicenseRequests;
    } catch (err) {
      console.error("Error getting users with license requests", err);
    }
  }

  async getAnswerSignedUrl(getAnswerSignedUrlInput: GetAnswerSignedUrlInput) {
    console.log("getAnswerSignedUrlInput", { getAnswerSignedUrlInput });
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getAnswerSignedUrl, { getAnswerSignedUrlInput })
      )) as GetAnswerSignedUrlResponse;
      console.log("answer signed url", data);
      return data.getAnswerSignedUrl;
    } catch (err) {
      console.error("Error getting answer signed url", err);
    }
  }

  async deleteAnswerAttachment(
    deleteAnswerAttachmentInput: DeleteAnswerAttachmentInput
  ) {
    console.log("deleteAnswerAttachment", { deleteAnswerAttachmentInput });
    try {
      const { data } = (await API.graphql(
        graphqlOperation(deleteAnswerAttachment, {
          deleteAnswerAttachmentInput
        })
      )) as DeleteAnswerAttachmentResponse;
      return data.deleteAnswerAttachment;
    } catch (err) {
      console.error("Error deleting answer attachment", err);
    }
  }

  async getMyLicenseRequestDetails(getLicenseRequestDetailsInput: {
    licenseRequestId: string;
  }) {
    console.log(
      "getMyLicenseRequestDetailsInput",
      getLicenseRequestDetailsInput
    );
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getMyLicenseRequestDetails, {
          getLicenseRequestDetailsInput
        })
      )) as any;
      console.log("getMyLicenseRequestDetails data", data);
      return data.getMyLicenseRequestDetails;
    } catch (err) {
      console.error("Error getting my license request details", err);
    }
  }

  async getLicenseRequestDetails(getLicenseRequestDetailsInput: {
    licenseRequestId: string;
  }) {
    console.log("getLicenseRequestDetailsInput", getLicenseRequestDetailsInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getLicenseRequestDetails, {
          getLicenseRequestDetailsInput
        })
      )) as any;
      console.log("data", data);
      return data.getLicenseRequestDetails;
    } catch (err) {
      console.error("Error getting license request details", err);
    }
  }

  async getCustomerLicenseRequests(getCustomerLicenseRequestsInput: {
    customerSub: string;
    projectFilter: string;
  }) {
    console.log(
      "getCustomerLicenseRequestsInput",
      getCustomerLicenseRequestsInput
    );
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getCustomerLicenseRequests, {
          getCustomerLicenseRequestsInput
        })
      )) as any;
      console.log("data", data);
      return data.getCustomerLicenseRequests;
    } catch (err) {
      console.error("Error getting customer licenses request", err);
    }
  }

  async requireLicenseRequestUpdates(requireLicenseRequestUpdatesInput: any) {
    console.log(
      "requireLicenseRequestUpdatesInput",
      requireLicenseRequestUpdatesInput
    );
    try {
      const { data } = (await API.graphql(
        graphqlOperation(requireLicenseRequestUpdates, {
          requireLicenseRequestUpdatesInput
        })
      )) as any;
      console.log("require updates result", data);
    } catch (err) {
      console.error("Error requiring updates for license request", err);
    }
  }

  async upsertLicenseTypeAnswers(
    upsertLicenseTypeAnswersInput: {
      licenseTypeQuestionId: number;
      licenseRequestId: number;
      answer: string;
    }[]
  ) {
    console.log("upsertLicenseTypeAnswersInput", upsertLicenseTypeAnswersInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(upsertLicenseTypeAnswers, {
          upsertLicenseTypeAnswersInput: {
            items: upsertLicenseTypeAnswersInput
          }
        })
      )) as any;
      console.log("created license type answers", data);
    } catch (err) {
      console.error("Error creating license type answers", err);
    }
  }

  async updateRates(updateRatesInput: {
    licenseRequestId: string;
    answers: [LicenseTypeAnswer];
  }) {
    console.log("updateRatesInput", updateRatesInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(updateRates, { updateRatesInput })
      )) as any;
      console.log("update rates data", data);
    } catch (err) {
      console.error("Error updating rates", err);
    }
  }

  async updateContract(updateContractInput: {
    licenseRequestId: string;
    contract: string;
  }) {
    console.log("updating contract with input: ", updateContractInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(updateContract, {
          updateContractInput
        })
      )) as any;
      console.log("updated contract", data.updateContract);
    } catch (err) {
      console.error("Error updating contract", err);
    }
  }

  async addWorkToQueue(createLicenseRequestInput: {
    musicMaestroId: number;
    openPlayId: number;
  }) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(createLicenseRequest, {
          createLicenseRequestInput
        })
      )) as any;
      return data;
    } catch (err) {
      console.error("Error adding work to queue", err);
    }
  }

  async deleteLicenseRequest(deleteLicenseRequestInput: { id: number }) {
    console.log("deleteLicenseRequestInput", deleteLicenseRequestInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(deleteLicenseRequest, {
          deleteLicenseRequestInput
        })
      )) as any;
      console.log("data.deleteLicenseRequest", data.deleteLicenseRequest);
      return data;
    } catch (err) {
      console.log("Error deleting license reqeust", err);
    }
  }

  async duplicateLicenseRequests(duplicateLicenseRequestsInput: {
    licenseRequestIds: [number];
  }) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(duplicateLicenseRequests, {
          duplicateLicenseRequestsInput
        })
      )) as any;
      console.log("data", data);
    } catch (err) {
      console.error("Error duplicating license requests", err);
    }
  }
  async getAllLicenseRequests(getAllLicenseRequestsInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getAllLicenseRequests, { getAllLicenseRequestsInput })
      )) as any;
      console.log("data", data);
      return data.getAllLicenseRequests;
    } catch (err) {
      console.error("Error getting all license requests", err);
    }
  }

  async getLicenseRequests(getLicenseRequestsInput: {
    projectFilter: string;
    status: string;
    includeQuestions: boolean;
    includeAnswers: boolean;
    includeAvailableLicenseTypes: boolean;
    internal: boolean;
    page: number;
    rowsPerPage: number;
  }) {
    console.log("getLicenseRequestsInput", getLicenseRequestsInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getLicenseRequests, {
          getLicenseRequestsInput
        })
      )) as any;
      // console.log("get license requests data", data.getLicenseRequests);
      return data.getLicenseRequests;
    } catch (err) {
      console.error("Error getting license requests", err);
    }
  }

  async getQueueSummary() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getQueueSummary)
      )) as any;
      console.log("queue data", data.getQueueSummary);
      return data.getQueueSummary;
    } catch (err) {
      console.error("Error getting queue summary", err);
    }
  }

  async getQueue(getQueueInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getQueue, { getQueueInput })
      )) as any;
      return data.getQueue;
    } catch (err) {
      console.error("Error getting queue", err);
    }
  }

  async startEnqueuedLicenseRequests(startEnqueuedLicenseRequestsInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(startEnqueuedLicenseRequests, {
          startEnqueuedLicenseRequestsInput
        })
      )) as any;
      console.log("startEnqueuedLicenseRequests", data);
      return data.startEnqueuedLicenseRequests;
    } catch (err) {
      console.error("startEnqueuedLicenseRequests", err);
      throw new Error("Error starting license requests.");
    }
  }

  async submitEnqueuedLicenseRequests(submitEnqueuedLicenseRequestsInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(submitEnqueuedLicenseRequests, {
          submitEnqueuedLicenseRequestsInput
        })
      )) as any;
      return data.submitEnqueuedLicenseRequests;
    } catch (err) {
      console.error("submitEnqueuedLicenseRequests", err);
      throw err;
    }
  }

  async downloadTransactionInformation(
    downloadTransactionInformationInput: any
  ) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(downloadTransactionInformation, {
          downloadTransactionInformationInput
        })
      )) as any;
      return data.downloadTransactionInformation;
    } catch (err) {
      console.error("downloadTransactionInformation", err);
    }
  }

  async downloadBatchedContracts(downloadBatchedContractsInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(downloadBatchedContracts, {
          downloadBatchedContractsInput
        })
      )) as any;
      return data.downloadBatchedContracts;
    } catch (err) {
      console.error("downloadBatchedContracts", err);
    }
  }

  async getReportDownloadLog(getReportLogInput: GetReportLogInput) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getReportDownloadLog, { getReportLogInput })
      )) as GetReportDownloadLogGraphQl;
      return data.getReportDownloadLog;
    } catch (err) {
      console.error("getReportDownloadLog", err);
    }
  }

  async voidLicenseRequest(voidLicenseRequestInput: VoidLicenseRequestInput) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(voidLicenseRequest, { voidLicenseRequestInput })
      )) as any;
      return data.voidLicenseRequest;
    } catch (err) {
      console.error("voidLicenseRequest", err);
    }
  }
}

export const licenseRequestService = new LicenseRequestService();
export default licenseRequestService;
