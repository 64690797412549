import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import {
  findOrCreateContract,
  getContractSignedUrl,
  getMyCart
} from "./graphql/queries";
import {
  signContractForCustomer,
  signContractForIntegrity
} from "./graphql/mutations";
import Amplify from "aws-amplify";
import { default as config } from "../config";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

class CheckoutService extends BaseService {
  async getContractSignedUrl(getContractSignedUrlInput: any) {
    const { data } = (await API.graphql(
      graphqlOperation(getContractSignedUrl, { getContractSignedUrlInput })
    )) as any;
    console.log("data", data);
    return data.getContractSignedUrl;
  }

  async getMyCart(getMyCartInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getMyCart, { getMyCartInput })
      )) as any;
      // console.log({ data });
      return data.getMyCart;
    } catch (err) {
      console.error("Error getting cart items", err);
    }
  }

  async findOrCreateContract(findOrCreateContractInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(findOrCreateContract, { findOrCreateContractInput })
      )) as any;
      console.log("got contract data", data);
      return data.findOrCreateContract;
    } catch (err) {
      console.error("Error getting cart items", err);
    }
  }

  async signContractCustomer(signContractInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(signContractForCustomer, { signContractInput })
      )) as any;
      console.log("signed contract data: ", data);
      return data.signContractForCustomer;
    } catch (err) {
      console.error("Error signing customer contract", err);
    }
  }

  async signContractIntegrity(signContractInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(signContractForIntegrity, { signContractInput })
      )) as any;
      console.log("signed contract data: ", data);
      return data.signContractForIntegrity;
    } catch (err) {
      console.error("Error signing customer contract", err);
    }
  }
}

export const checkoutService = new CheckoutService();
export default checkoutService;
