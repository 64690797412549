import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import {
  publicGetWork,
  protectedGetWork,
  listAllLicenseTypes
} from "./graphql/queries";
import { graphqlClient } from "../utils";
import gql from "graphql-tag";

import Amplify from "aws-amplify";
import { default as config } from "../config";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

interface GetWorkInput {
  authenticated: boolean;
  region: "US" | "UK";
  id: string;
}

class WorkService extends BaseService {
  async listAllLicenseTypes() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(listAllLicenseTypes)
      )) as any;
      return data.listAllLicenseTypes;
    } catch (err) {
      console.error("COULD NOT FETCH ALL LICENSE TYPES", err);
    }
  }
  async getWork(params: GetWorkInput) {
    const { authenticated, id, region } = params;
    let work, musicMaestroId, openPlayId;
    if (id.length > 2 && id.substring(0, 2) === "mm") {
      musicMaestroId = id.substring(2);
    } else if (id.length > 2 && id.substring(0, 2) === "op") {
      openPlayId = id.substring(2);
    } else {
      throw new Error("Invalid Work Id: " + id);
    }
    try {
      if (authenticated) {
        const { data } = (await API.graphql(
          graphqlOperation(protectedGetWork, {
            musicMaestroId,
            openPlayId,
            id: null,
            version: 0,
            region
          })
        )) as any;
        work = data.protectedGetWork;
      } else {
        const query = publicGetWork
          .replace(
            "[musicMaestroId]",
            musicMaestroId ? `"${musicMaestroId}"` : `null`
          )
          .replace("[openPlayId]", openPlayId ? `"${openPlayId}"` : `null`)
          .replace("[region]", `"${region}"`)
          .replace("[id]", `null`)
          .replace("[version]", `0`);
        const gqledQuery = gql(query);
        const { data } = (await graphqlClient.query({
          fetchPolicy: "network-only",
          query: gqledQuery
        })) as any;
        work = data.publicGetWork;
      }
      console.log("FETCHED WORK", work);
      return work;
    } catch (err) {
      console.error("COULD NOT FETCH WORK", err);
    }
  }
}

export const workService = new WorkService();
export default WorkService;
