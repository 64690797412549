import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import {
  completeOrder,
  createOrUpdateOrder,
  createStripeSetupIntent,
  createOrUpdateStripeCard,
  deleteStripePaymentMethod
} from "./graphql/mutations";
import { getIncompleteOrder, getStripePaymentMethod } from "./graphql/queries";

import Amplify from "aws-amplify";
import { default as config } from "../config";

Amplify.configure({
  Auth: {
    identityPoolId: config.aws.identitypoolid,
    region: config.aws.cognitoregion,
    userPoolId: config.aws.userpoolid,
    userPoolWebClientId: config.aws.webclientid
  },
  API: {
    aws_appsync_graphqlEndpoint: config.aws.apiurl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  },
  aws_appsync_graphqlEndpoint: config.aws.apiurl,
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

class StripeService extends BaseService {
  async createStripeSetupIntent() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(createStripeSetupIntent, {})
      )) as any;
      return data.createStripeSetupIntent;
    } catch (err) {
      console.error("Error creating stripe setup intent", err);
    }
  }

  async completeOrder(orderId: string) {
    console.log("completeOrder payload orderId", orderId);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(completeOrder, {
          orderId
        })
      )) as any;
      console.log("order completed", data);
      return data.completeOrder;
    } catch (err) {
      console.error("Error confirming payment intent", err);
      throw `Error confirming payment intent ${err}`;
    }
  }

  async getIncompleteOrder() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getIncompleteOrder)
      )) as any;
      console.log("getIncompleteOrder stripe service response", data);
      return data.getIncompleteOrder;
    } catch (err) {
      console.error("Error Getting Incomplete Order", err);
    }
  }

  async createOrUpdateStripeCard(createOrUpdateStripeCardInput: any) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(createOrUpdateStripeCard, {
          createOrUpdateStripeCardInput
        })
      )) as any;
      console.log("createOrUpdateStripeCard stripe service response", data);
      return data.createOrUpdateStripeCard;
    } catch (err) {
      console.error("Error creating or updating stripe card", err);
    }
  }

  async createOrUpdateOrder(createOrUpdateOrderInput: any) {
    console.log("createOrUpdateOrderInput", createOrUpdateOrderInput);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(createOrUpdateOrder, {
          createOrUpdateOrderInput
        })
      )) as any;
      console.log("data", data);
      return data.createOrUpdateOrder;
    } catch (err) {
      console.log("Error creating stripe payment", err);
    }
  }

  async deleteStripePaymentMethod(paymentMethodId: string) {
    console.log("deleteStripePaymentMethodInput", paymentMethodId);
    try {
      const { data } = (await API.graphql(
        graphqlOperation(deleteStripePaymentMethod, {
          deleteStripePaymentMethodInput: {
            paymentMethodId
          }
        })
      )) as any;
      console.log("data", data);
      return data.deleteStripePaymentMethod;
    } catch (err) {
      console.log("Error deleting payment method", err);
    }
  }

  async getStripePaymentMethod() {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(getStripePaymentMethod, {})
      )) as any;
      console.log("get stripe payment method information", data);
      return data.getStripePaymentMethod;
    } catch (err) {
      console.error("Error getting payment method", err);
    }
  }
}

export const stripeService = new StripeService();
export default stripeService;
