import { Organization, Role, User } from "integrity-owls-logic/dist";
import { SET_PROFILE } from "../../constants";
import { ReduxProfileState } from "../../interfaces/Shared";

const initialState: ReduxProfileState = {
  id: 0,
  username: "",
  status: "",
  sub: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  town: "",
  county: "",
  postcode: "",
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  organizationId: 0,
  organization: new Organization(),
  role: new Role(),
  permissions: [],
  region: null,
  licenseRequests: [],
  stripeOrders: [],
  createdAt: new Date(),
  createdBy: new User(),
  updatedAt: new Date(),
  updatedBy: new User(),
  fetching: true,
  notifications: null
};

interface ProfileAction {
  type: string;
  payload: ReduxProfileState;
}

function profile(
  state = initialState,
  action: ProfileAction
): ReduxProfileState {
  switch (action.type) {
    case SET_PROFILE:
      return { ...state, ...action.payload, fetching: false };
    default:
      return state;
  }
}

export default profile;
