import config from "../config";
import AWSAppSyncClient from "aws-appsync";

const graphqlClient = new AWSAppSyncClient({
  url: config.aws.publicapiurl,
  region: "us-east-1",
  auth: {
    type: "API_KEY",
    apiKey: config.aws.publicapikey
  }
});

export { graphqlClient };
