import { API, graphqlOperation } from "aws-amplify";
import BaseService from "./BaseService";
import { fetchMyProfile } from "./graphql/queries";
import {
  updateProfile,
  updateMyOrganization,
  requestDeactivation,
  updateNotifications
} from "./graphql/mutations";

interface IUpdateProfile {
  email?: string;
  phone?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  town?: string;
  county?: string;
  postcode?: string;
  firstName?: string;
  lastName?: string;
  region?: "US" | "UK" | null;
}

interface UpdateMyOrganizationInterface {
  organizationName?: string;
  organizationEmail?: string;
  organizationAddress1?: string;
  organizationAddress2?: string;
  organizationCity?: string;
  organizationState?: string;
  organizationZip?: string;
  organizationTown?: string;
  organizationCounty?: string;
  organizationPostcode?: string;
}

interface UpdateNotificationsInput {
  approved: boolean;
  denied: boolean;
  needsUpdates: boolean;
}

class ProfileService extends BaseService {
  async fetchMyProfile() {
    try {
      const profile = (await API.graphql(
        graphqlOperation(fetchMyProfile)
      )) as any;
      console.log("FETCHED PROFILE", profile.data.getProfile);
      return profile.data.getProfile;
    } catch (err) {
      console.error("COULD NOT FETCH PROFILE", err);
    }
  }

  async updateProfile(updateProfileInput: IUpdateProfile) {
    const response = (await API.graphql(
      graphqlOperation(updateProfile, { updateProfileInput })
    )) as any;
    return response.data.updateProfile;
  }

  async updateNotifications(
    updateNotificationsInput: UpdateNotificationsInput
  ) {
    const response = (await API.graphql(
      graphqlOperation(updateNotifications, { updateNotificationsInput })
    )) as any;
    return response.data.updateNotifications;
  }

  async updateMyOrganization(
    updateMyOrganizationInput: UpdateMyOrganizationInterface
  ) {
    console.log("updateMyOrganizationInput", updateMyOrganizationInput);
    try {
      const data = (await API.graphql(
        graphqlOperation(updateMyOrganization, { updateMyOrganizationInput })
      )) as any;
      console.log("data from update org", data);
      console.log("updated organization", data.updateMyOrganization);
      return data.updateMyOrganization;
    } catch (err) {
      console.error("could not update organization", err);
      if (err.errors.length > 0) {
        if (err.errors[0].message.includes("User does not have")) {
          throw new Error("You do not have permission to update this content.");
        }
      }
      throw err;
    }
  }

  async requestDeactivation() {
    console.log("requestDeactivation");
    try {
      const data = (await API.graphql(
        graphqlOperation(requestDeactivation)
      )) as any;
      console.log("data from api", data);
      return data.requestDeactivation;
    } catch (err) {
      console.error("could not request deactivation", err);
      if (err.errors.length > 0) {
        if (err.errors[0].message.includes("User does not have")) {
          throw "You do not have permission to update this content.";
        }
      }
      throw err;
    }
  }
}

export const profileService = new ProfileService();
export default ProfileService;
